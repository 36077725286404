<template>
  <div class="autoSuggest position-relative" v-click-out-side="() => hideSuggestions()" @keydown="keyMove">
      <div class="input-group">
          <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-magnifying-glass text-primary"></i></span>
          <input type="text" class="form-control" :placeholder="$t('menu.quickSearch')"  v-model="search" @focus="showSuggestions()" >
      </div>

      <transition>
         <i class="fa-solid fa-sync text-primary fa-spin" v-if="loading"></i>
      </transition>

      <div class="suggestens position-absolute w-100" style="z-index: 99" v-if="suggestionsVisible">
          <div class="card overflow-hidden mt-2">
              <div class="card-body p-0">
                <ul class="list-group list-group-flush">
                  <template v-for="(item, index) in items" :key="item.id">
                    <li class="list-group-item list-group-item-action" :class="[index == selected ? 'active' : '']" @click="select(index)">
                      <div class="d-flex w-100 justify-content-between">
                        <div v-if="item.ref_type == 'contact'">
                          {{item.ref.firstName}} {{item.ref.lastName}} <br>
                          <div>
                            <small class="text-muted" style="font-size: 0.75rem; line-height: 1.3em;">
                              <span class="text-secondary text-bold d-block ps-4 position-relative"><i class="fa-solid fa-id-card-clip fa-fw position-absolute top-0 start-0"></i> {{item.ref.usedSoftId}}</span>
                              <span v-if="item.ref.company" class="text-secondary text-bold d-block ps-4 position-relative"><i class="fa-solid fa-building fa-fw position-absolute top-0 start-0"></i> {{item.ref.company.name}}</span>
                              <span v-if="item.ref.address" class="text-secondary text-bold d-block ps-4 position-relative"><i class="fa-solid fa-location-dot fa-fw position-absolute top-0 start-0"></i> {{ item.ref.address.street }} {{ item.ref.address.streetHouseNo }} | {{ item.ref.address.city }} | {{ item.ref.address.country_id }}</span>
                            </small>
                          </div>
                        </div>
                        <div v-if="item.ref_type == 'company'">
                          {{item.ref.name}} <br>
                          <div>
                            <small class="text-muted" style="font-size: 0.75rem; line-height: 1.3em;">
                              <span class="text-secondary text-bold d-block ps-4 position-relative"><i class="fa-solid fa-id-card-clip fa-fw position-absolute top-0 start-0"></i> {{item.ref.crm_id}}</span>
                            </small>
                          </div>
                        </div>
                        <div>
                          <div class="typeIcon" style="font-size: 1.2rem;">
                            <i v-if="item.ref_type == 'contact'" class="fa-solid fa-user fa-fw"></i>
                            <i v-if="item.ref_type == 'company'" class="fa-solid fa-building fa-fw"></i>
                          </div>
                        </div>
                      </div>
                    </li>
                  </template>
                   <!-- <template v-for="(item, index) in items" :key="item.id">
                        <li class="list-group-item list-group-item-action" :class="[index == selected ? 'active' : '']" @click="select(index)">
                            <div class="d-flex w-100 justify-content-between">
                                <div>
                                     {{item.value}}<br>
                                    <div>
                                        <small class="text-muted" style="font-size: 0.75rem; line-height: 1.3em;">
                                            <span class="text-secondary text-bold d-block ps-4 position-relative"><i class="fa-solid fa-id-card-clip fa-fw position-absolute top-0 start-0"></i> {{item.usedSoftIdFormatted}}</span>
                                            <span v-if="item.company" class="text-secondary text-bold d-block ps-4 position-relative"><i class="fa-solid fa-building fa-fw position-absolute top-0 start-0"></i> {{item.company}}</span>
                                            <span v-if="item.address" class="text-secondary text-bold d-block ps-4 position-relative"><i class="fa-solid fa-location-dot fa-fw position-absolute top-0 start-0"></i> {{ item.address }}</span>
                                        </small>
                                    </div>
                                </div>
                                <div>
                                    <div class="typeIcon" style="font-size: 1.2rem;">
                                        <i v-if="item.type == 'contact'" class="fa-solid fa-user fa-fw"></i>
                                        <i v-if="item.type == 'company'" class="fa-solid fa-building fa-fw"></i>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </template>-->
                </ul>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
    name: "autoSuggest",
    directives: {
        clickOutSide,
    },
    computed: {
        suggestionsVisible(){
            if(this.items.length == 0) return false;

            return this.enableSuggestions;
        }
    },
    watch: {
        search(){
            this.enableSuggestions = true;

            if(this.searchDelay) {
                clearTimeout(this.searchDelay);
            }
            this.searchDelay = setTimeout(() => {
                this.quickSearch();
            }, 300);
        }
    },
    data(){
        return {
            selected: 0,
            items: [],
            enableSuggestions: false,
            search: '',
            searchDelay: null,
            loading: false,
        };
    },
    methods:{
        quickSearch(){
            if(this.loading) return;

            if(this.search == ''){
                this.items = [];
                return;
            }

            this.loading = true;

            this.axios.get("crm/quicksearch",{params: {term: this.search}}).then(res => {
                this.loading = false;
                this.selected = 0;
                this.items = res.data;
            }).catch(
                error => {
                    this.loading = false;
                    this.items = [];
                    alert("Ein Fehler ist aufgetreten");
            });
        },
        hideSuggestions(){
            this.enableSuggestions = false;
        },
        showSuggestions(){
            this.enableSuggestions = true;
        },
        select(index){
            this.selected = index;
            //this.search = this.items[index].value;
            switch(this.items[index].ref_type){
                case 'company':
                    this.$router.push({name:'company', "params": {id: this.items[index].ref_id}});
                    //this.$router.push({name: "recept", "params": {id: this.ice.id}});
                    break;
                case 'contact':
                    this.$router.push({name:'contact', "params": {contactId: this.items[index].ref_id}});
                    //this.$router.push({name: "recept", "params": {id: this.ice.id}});
                    break;
            }

            this.hideSuggestions();
        },
        keyMove(e){
          switch(e.keyCode){
              case 13:
                  e.preventDefault();
                  this.select(this.selected);
                  break;
              case 38:
                  e.preventDefault();
                  if(this.selected == 0){
                      this.selected = this.items.length -1;
                  }
                  else{
                      this.selected--;
                  }
                  break;
              case 40:
                  e.preventDefault();
                  if(this.selected == this.items.length -1){
                      this.selected = 0;
                  }
                  else{
                      this.selected++;
                  }
                  break;
          }
          return;
        }
    }
}
</script>

<style scoped>

</style>