import { useRouter, useRoute } from 'vue-router'
import {inject} from "vue";

const Router = useRouter();
const Route = useRoute();

const HOME = {
    key: "/",
    title: "Home",
    path: "/",
    name: "home",
    icon: "fa-solid fa-house-chimney",
}
export default (app) =>  {
    return {
        namespaced: true,
        state () {
            return {
                tabs: [],
                active: null,
                router: null,
            }
        },
        mutations: {
            initialiseStore(state){
                state.tabs =  [ HOME ];
                //state.tabs = JSON.parse(localStorage.getItem('tabs')) || [ HOME ];
            },
            activeRoute(state, route){

            },
            setRouter(state, router){
                state.router = router;
            },
            delRoute(state, route){
                for(let tab in state.tabs){
                    //if(tab.key == route.matched[0].path) return true;
                    if(state.tabs[tab].key == route.fullPath){
                        if(state.tabs[tab].key == state.active.key){
                            state.router.push(state.tabs[tab-1].key);
                        }
                        state.tabs.splice(tab, 1);
                    }
                }
            },
            setActive(state, active){

            },
            setTitle(state, title){
              state.active.title = title;
            },
            addRoute(state, route){
                for(let tab of state.tabs){
                    //if(tab.key == route.matched[0].path) return true;
                    if(tab.key == route.fullPath){
                        state.active = tab;
                        app.config.globalProperties.$tab = tab;
                        return true;
                    }
                }

                if(route.matched.length > 1){
                   alert('children');
                }

                if(route.meta.tab != undefined && route.meta.tab){
                    return true;
                }

                let newTab = {
                    key: route.fullPath, //route.matched[0].path,
                    title: route.meta.title || "Tab",
                    icon: route.meta.icon || false,
                    path: route.matched[0].path,
                    name: route.matched[0].name,
                    params: route.params,
                };
                app.config.globalProperties.$tab = newTab;
                state.active = newTab;
                state.tabs.push(newTab);
                localStorage.setItem('tabs', JSON.stringify(state.tabs));
            }
        },
        actions: {

        }
    };
};