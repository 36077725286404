<template>
    <div id="formLogin">
      <div class="container-fluid h-100">
        <div class="row h-100 align-items-center justify-content-center">
          <div id="innerForm" class="col">
              <img class="shadowLogo" src="@/assets/usedSoft_shadow.svg">

            <Transition name="slide-up">
              <div v-if="!enable2Fa">
                  <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-at fa-fw text-primary"></i></span>
                      <input type="text" class="form-control " :placeholder="$t('login.user')" v-model="formData.email" :disabled="enable2Fa || loading" name="user" ref="email" id="email"  @keyup.enter="nextField()">
                  </div>

                  <div class="input-group  mb-3">
                      <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-lock fa-fw text-primary"></i></span>
                      <input type="password" class="form-control" :placeholder="$t('login.password')" v-model="formData.password" :disabled="enable2Fa || loading" name="password" ref="password" id="password" @keyup.enter="nextField()">
                  </div>
              </div>
              <div v-else>
                <b>{{ $t('login.2fa') }}</b>
                <p>{{$t('login.2fa_intro')}}</p>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-shield-halved fa-fw text-primary"></i></span>
                    <template v-for="(n, index) in formData.twoFA.length" :key="index">
                      <input type="text" class="form-control text-center" maxlength="1" placeholder="" v-model="formData.twoFA[index]" :disabled="loading" name="password" ref="twofa" :id="'twofa'+index" @input="checkInput(index)">
                    </template>
                  </div>
                <p class="text-muted small" v-html="$t('login.2fa_hints')">

                </p>
              </div>
            </Transition>
              <div class="row">
                <div class="col-auto" v-if="enable2Fa">
                  <button class="btn btn-danger"  :disabled="loading" @click="enable2Fa = false"><i class="fa-solid fa-rotate-left"></i></button>
                </div>
                <div class="col">
                  <div class="d-grid gap-2">
                    <button class="btn btn-primary" @click="login()" :disabled="loading"><i class="fa-solid fa-spinner-third fa-fw fa-spin" v-if="loading"></i> {{$t('login.login')}}</button>
                  </div>
                </div>
              </div>

              <p class="small text-muted my-2">© {{ $filters.date(new Date(), "YYYY") }} bitDivision GmbH</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "formLogin",
  data(){
    return {
        enable2Fa: false,
        loading: false,
        formData: {
          email: "",
          password: "",
          twoFA: Array(6),
        }
    }
  },
  mounted() {
    let timeout = setInterval(() => {
      if (this.$auth.ready()) {
        clearInterval(timeout);
      }
    });
  },
  methods: {
      checkInput(index) {
        this.formData.twoFA[index] = this.formData.twoFA[index].replace(/[^0-9]/g, '');

        if(this.formData.twoFA[index] && this.formData.twoFA.length-1 > index){
          this.$refs.twofa[index+1].focus();
        }
        else if(this.formData.twoFA.length-1 == index){
          this.login();
        }
      },
      nextField(){
          if(!this.formData.email){
              this.$refs.email.focus();
              return;
          }
          if(!this.formData.password){
              this.$refs.password.focus();
              return;
          }

          this.login();
      },
      login(){
        let redirect = this.$auth.redirect();
        let app = this;

        this.loading = true;

        let data = {
              email: this.formData.email,
              password: this.formData.password
        };

        if(this.enable2Fa){
            data.two_fa_code = this.formData.twoFA.join('');
        }

        this.$auth.login( {
          data: data,
          rememberMe: true,
          fetchUser: true,
          staySignedIn: true,
        }).then(res => {
          app.has_error = false;
          this.loading = false;
          this.$root.setLocale(this.$auth.user().language);
          this.$root.init();
        }, (res) => {

          if(res.response.data.language !== undefined){
            this.$i18n.locale = res.response.data.language;
          }

          if(res.response.data.status !== undefined && res.response.data.status === "2fa"){
            this.enable2Fa = true;

            setTimeout(() => {
              this.$refs.twofa[0].focus();
            }, 300);
          }
          else if(res.response.data.status !== undefined && res.response.data.status === "2fa_failed"){
            this.$toast.error(this.$t('login.errors.2faFailed'));
            this.formData.twoFA = Array(6);
            setTimeout(() => {
              this.$refs.twofa[0].focus();
            }, 300);
          }
          else{
            this.$toast.error(this.$t('login.errors.wrongCredentials'));
          }

          this.loading = false;

        });
      }
  }
}
</script>

<style scoped>
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.25s ease-out;
  }

  .slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
  }

  .slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
</style>