<template>
    <div class="phoneNumberSelect">
        <label v-if="label"
               :for="id" class="form-label" :class="[$attrs.required !== undefined ? 'required' : '']">{{ label }}</label>
        <div class="input-group" v-if="$store.state.countries && $store.state.countries.length > 0">
              <span class="input-group-text" id="basic-addon1"><i :class="'fa-solid fa-' + icon"></i></span>
              <span class="input-group-text dropdown-toggle text-muted" id="basic-addon1" data-bs-toggle="dropdown"  :ref="addEvent" style="cursor: pointer">{{ number.int }}</span>
              <div class="dropdown-menu" >
                  <div class="dropdown-header">
                      <input class="form-control" placeholder="Suche" ref="countrySearch" v-model="search">
                  </div>
                  <perfect-scrollbar style="max-height: 400px; overflow: auto;">
                  <ul style="list-style: none; padding: 0;">
                      <li v-for="country in countries" :key="country.id">
                          <a class="dropdown-item" :class="[country.country_phone == number.int ? 'active' : '']" style="cursor: pointer;" @click="number.int = country.country_phone">
                              <small style="width: 40px; text-align: right; margin-right: 0.75rem; display: inline-block;">{{country.country_phone}}</small>
                              <small :class="[country.country_phone != number.int ? 'text-muted' : '']">{{country.name}}</small>
                          </a>
                      </li>
                  </ul>
                  </perfect-scrollbar>
              </div>
              <input class="form-control" style="flex: 0.6 0 auto;" placeholder="Vorwahl" v-model="number.prefix" @keypress="onlyNumber">
              <input :id="id" class="form-control flex-grow-1" placeholder="Nummer" v-model="number.number" @keypress="onlyNumber">
              <span class="input-group-text" id="basic-addon1">-</span>
              <input class="form-control" style="flex: 0.4 0 auto;" placeholder="Ext" v-model="number.ext" @keypress="onlyNumber">
              <div v-if="hasError()" class="invalid-feedback">
                  <ul class="inputError">
                      <li v-for="(error, index) in errors[this.name]" :key="index">{{error}}</li>
                  </ul>
              </div>
        </div>
    </div>

</template>

<script>

import {generateUUID} from "@/helper";
export default {
    name: "phoneNumberInput",
    props: {
        modelValue: {
            type: String,
        },
        defaultCountry:{
            type: String,
            default: null,
        },
        defaultInt: {
            type: String,
        },
        icon: {
            default() {
                return 'phone';
            }
        },
        id:{
            type: String,
            default(){
                return generateUUID();
            }
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default() {
                return 'text';
            }
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
        label:{
            type: String,
        }
    },
    mounted() {
        this.transformNumber(this.value);

        if(this.defaultCountry){
           for(let country in this.$store.state.countries){
             if(this.$store.state.countries[country].country_code_iso == this.defaultCountry){
                this.number.int = this.$store.state.countries[country].country_phone;
                return;
             }
           }
        }
    },
    computed:{
        value: {
            get(){
                return this.modelValue;
            }
        },
        countries(){
            const filtered = this.$store.state.countries.filter((country) => {
                if(this.search.trim() == '') return true;
                return  country.name.search(this.search) >= 0;

              }
            );

            return filtered;
        }
    },
    //emits: ['update:modelValue'],
    watch:{
        modelValue(value, OldValue){
          this.transformNumber(value);
        },
        number:{
            handler(value){
                let formated = '';
                let valid = false;

                if(value.int){
                    formated = value.int;
                }

                if(value.prefix){
                    formated += " " + value.prefix;
                }
                else{
                    formated += " "
                }

                if(value.number){
                    formated += " " + value.number;
                }
                else{
                    formated += " "
                }

                if(value.ext){
                    formated += "-" + value.ext;
                }

                if(formated != this.value){
                    this.$emit('update:modelValue', formated);
                }
            },
            deep: true,
        }
    },
    unmounted() {
        this.dropDown.element.removeEventListener('shown.bs.dropdown', this.dropDown.handle);
    },
    methods:{
        addEvent(event){
            if(event === undefined) return;
            if(event == null) return;

            this.dropDown.element = event;

            this.dropDown.handle =  event.addEventListener('shown.bs.dropdown', event => {
                this.$refs.countrySearch.select();
            })

        },
        transformNumber(value){
            if(value === undefined || value == null){
                this.number = {
                    int: "",
                    prefix: "",
                    number: '',
                    ext: '',
                };
                return;
            }

            let split = value.split(" ");
            let splitExt = null;

            if(split[0] !== undefined){
                this.number.int = split[0];
            }

            if(split[1] !== undefined){
                this.number.prefix = split[1];
            }

            if(split[2] !== undefined){
                splitExt  = split[2].split('-');
                if(splitExt[0] !== undefined)
                {
                    this.number.number = splitExt[0];
                }

                if(splitExt[1] !== undefined)
                {
                    this.number.ext = splitExt[1];
                }
            }
        },
        onlyNumber ($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        hasError(){
            return this.errors[this.name] !== undefined;
        }
    },
    data(){
        return{
           dropDown:{
              element: null,
              handle: null,
           },
           search: '',
           explode: [],
           prefixIndex: 0,
            number:{
                int: "",
                prefix: "",
                number: '',
                ext: '',
            },
        }
    },
}
</script>

<style scoped>

</style>