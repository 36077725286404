<template>
    <div class="dataView" :class="[loading ? 'dataView-loading' : '']">
        <div class="loadingScreen container-fluid" v-if="loading"><!--<div class="row h-100 align-items-center"><div class="col text-center"><i class="text-primary fa-solid fa-circle-notch fa-spin fa-3x"></i></div></div>--></div>
        <table class="table table-hover table-striped table-bordered table-sm b-table-sticky-header m-0">
          <caption v-if="title">{{title}}</caption>
          <thead class="table-secondary" style="position: sticky; top: 0; z-index: 1;  border-width: 0;">
            <tr >
              <th v-for="field in fields"
                  :key="field.key"
                  :class="[field.class,
                  field.thClass,
                  field.variant ? `table-${field.variant}` : '',
                  field.sortable ? 'sortable' : '',
                  field.key ==  sortingBy? 'sort-active' : ''
                  ]"
                  style="border-width: 0;"
                  @click="thClicked(field)"
                  @contextmenu.prevent="event => thRightClicked(field, event)"
              >
                <span v-html="getTableHeadName(field)"></span>

                <div v-if="field.sortable" class="sortable-icon">
                  <span v-show="isField(field)"><i class="fa-solid fa-sort-down"></i></span>
                  <span v-show="isFieldDesc(field)"><i class="fa-solid fa-sort-up"></i></span>
                  <span v-show="!isField(field) && !isFieldDesc(field)"><i class="fa-solid fa-sort"></i></span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row) in data"
                :key="row"
                @click="rowClick(row)"
                @dblclick="rowDblClick(row)"
                @contextmenu.prevent="event => rowRightClicked(row, event)"
                :class="{
                  [`table-${row._rowVariant}`]: row._rowVariant
                }"
            >

              <td v-for="field in fields"
                  :key="field"
                  :class="[field.tdClass]"
                  @click="cellClick(row)"
                  @dblclick="cellDblClick(row)">

              <slot :name="'cell(' + field.key.replaceAll('.', '-').toString() + ')'" :row="row" :value="row[field.key]">
                {{ getValue(field.key, row )}}
              </slot>
              </td>
             <!-- <td v-for="(cell, cIndex) in getNormalizedTableRow(row)"
                  :key="cell"

                  @click="cellClick(row)">
                <slot :name="'cell(' + cIndex.toString() + ')'" :row="row" :value="cell">
                </slot>
              </td>-->
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
import { nextTick } from 'vue'

export default {
  name: "dataView",
  props: {
    fields: Array,
    data: Array,
    title: String,
    msg: String,
    sortBy: String,
    sortDesc: Boolean,
    loading: Boolean,
  },
  emits: ['rowClick', 'rowRightClick', 'rowDblClick', 'cellClick', 'cellDblClick', 'update:sort-by', 'update:sort-desc'],
  data(){
      return {
        sortingBy: '',
        sortingDesc: false,
      }
  },
  watch:{
      sortingBy(value){
        return value;
      }
  },
  mounted() {
    if(!this.fields){
      alert('Keine Felder definiert');
    }

    this.sortingDesc = this.sortDesc;
    this.sortingBy = this.sortBy;
  },
  computed:{
      selectedKey(){
        return this.sortingBy;
      },
      selectedDesc(){
        return this.sortingDesc;
      }
  },
  methods:{
    getValueAlt(obj,desc){
      let arr = desc.split(".");
      while(arr.length && (obj = obj[arr.shift()]));
      return obj;
    },
    getValue(key, item){
      return key.split('.').reduce((a, b) => {
        if(a === null) {
            return null;
        }

        if(typeof a[b] === "undefined") {
            return null;
        }

        return a[b];

      }, item);
    },
    isField(field){
      return field.key == this.sortingBy && !this.sortingDesc;
    },
    isFieldDesc(field){
        return field.key == this.sortingBy && this.sortingDesc;
    },
    getTableHeadName(field){
      if(field.label){
        return field.label;
      }

      return field.key;
    },
    getNormalizedTableRow(row){
      let nRow = {};

      for(let field of this.fields){
        nRow[field.key] = row[field.key];
      }

      return nRow;
    },
    thClicked(field){
      if(!field.sortable) return;

      if(field.key == this.sortingBy){
          this.sortingDesc = !this.sortingDesc;
          this.$emit('update:sort-desc', this.sortingDesc);
      }
      else{
          this.sortingBy = field.key;
          this.$emit('update:sort-by', this.sortingBy);
      }

      this.$forceUpdate();

    },
    thRightClicked(field, event){
      //alert('Rechts click....');
    },
    cellClick(row){
      this.$emit('cellClick', row);
    },
    cellDblClick(row){
      this.$emit('cellDblClick', row);
    },
    rowClick(row){
      this.$emit('rowClick', row);
    },
    rowRightClicked(row, event){
      this.$emit('rowRightClick', row);
    },
    rowDblClick(row){
      //alert('?');
      this.$emit('rowDblClick', row);
    }
  }
}
</script>