import { createStore as createVuexStore } from 'vuex';

import tabs from './tab';

export const createStore = (app) => {
    return createVuexStore({
        modules: {
            tabbar: tabs(app),
        },
        state() {
            return {
                phone:[],
                reminder: {
                    reminders: [],
                    next: [],
                },
                notifications: {
                  counts: {
                      count: 0,
                      flaggingCount: 0,
                      releasesCount: 0,
                  },
                  notifications: {
                      flagging: [],
                      releases: [],
                  },
                },
                status: {
                    stock: {
                        stockResidue: [],
                    },
                    orders:{},
                    sales: {}
                },
                mediakitLanguages: [],
                branches: [],
                users: [],
                online: [],
                userRoles: [],
                countries: [],
                customerTypes: [],
                actionTypes: [],
                actionDeclinations: [],
                businessQuarter: {},
                businessYear: {},
                categories: {},
            }
        },
        mutations: {
            setConfig(state, config){
                state.users = config.users;
                state.userRoles = config.userRoles;
                state.countries = config.countries;
                state.branches = config.branches;
                state.customerTypes = config.customerTypes;
                state.actionTypes = config.actionTypes;
                state.actionDeclinations = config.actionDeclinations
                state.businessQuarter = config.businessQuarter;
                state.businessYear = config.businessYear;
                state.mediakitLanguages = config.mediakitLanguages;
                state.categories = config.categories;
            },
            setStatus(state, status){
                state.status = status;
            },
            setNotifications(state, notifications){
                state.notifications = notifications;
            },
            setReminders(state, reminders){
                state.reminder = reminders;
            },
            setCalls(state, calls){
                state.phone = calls;
            },
            setUsers(state, users){
                state.users = users;
            },
            setBranches(state, branches){
                state.branches = branches;
            },
            setUsersOnline(state, users) {
                state.online = users;
            },
            setUserOnline(state, user) {
                if(!state.online.some((e, i, a) => {
                    return e.id == user.id;
                })){
                    state.online.push(user);
                }
            },
            setUserOffline(state, user) {
                let index = state.online.findIndex(element => {
                    return element.id == user.id;
                });

                if(index !== false){
                    state.online.splice(index, 1);
                }
            },
        }
    });
};