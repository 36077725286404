import { createApp } from 'vue'
import {setupI18n} from "@/i18n";
import router from './router';
import App from './App.vue'
import {createStore} from "@/store";
import axios from 'axios'
import VueAxios from 'vue-axios'
import auth from "@/auth";

import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import debug from './plugins/debug';

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'


import 'moment/locale/de';
import moment from "moment";

import VueToast from 'vue-toast-notification';

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import VueCookies from 'vue-cookies'


import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

export const i18n = setupI18n();

import { Form, Field, ErrorMessage } from 'vee-validate';


/*import * as PusherPushNotifications from "@pusher/push-notifications-web";
import './registerServiceWorker'

const beamsClient = new PusherPushNotifications.Client({
    instanceId: "673fa59f-6804-40fc-bb57-6b2d6962ce3b",
});

beamsClient.start()
    .then(() => beamsClient.addDeviceInterest('hello'))
    .then(() => console.log('Successfully registered and subscribed!'))
    .catch(console.error);*/

window.Pusher = require('pusher-js');


import * as yup from 'yup';
import { setLocale } from 'yup';

setLocale({
    mixed: {
        default: 'ungültig',
        required: '${path} wird benötigt.',
    },
    string: {
        min: 'Felder ${min}'
    },
    number: {
        min: '${path} ser maior que ${min}',
    },
});



//import Echo from 'laravel-echo';
//import VueEcho from 'vue-echo-laravel';

export const app = createApp(App);
const store = createStore(app);

app.config.globalProperties.$filters = {
    image(sourceArray, size){

        return sourceArray[size].webp;

        //return null;
    },
    formatCurrency(value){
        if(!value){
            value = 0.00;
        }

        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(parseFloat(value));
        //return new Intl.NumberFormat('de-CH', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2  }).format(parseFloat(value));
    },
    formatCurrencyInt(value){
        if(!value){
            value = 0.00;
        }

        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR',  minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(value));
        //return new Intl.NumberFormat('de-CH', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2  }).format(parseFloat(value));
    },
    date(value, format){
        if(format === undefined) format = 'DD.MM.YYYY'
        return moment(value).format(format);
    },
    humanize(value, format){
        if(format === undefined) format = 'DD.MM.YYYY'
        return moment(value).calendar();
    },
    secondsToTime(value){
        if(value < 3600) moment.utc(value*1000).format('mm:ss');
        if(value >= 86400) return moment.utc(value*1000).format('D [Tage] HH:mm:ss');

        return moment.utc(value*1000).format('HH:mm:ss');
    }
}

app.config.globalProperties.$online = {
    isOnline(user){
        return store.state.online.some((e, i, a) => {
            return e.id == user.id;
        });
    }
}



app .use(router)
    .use(i18n)
    .use(PerfectScrollbar)
    .use(VueAxios, axios)
    //.use(SimpleTypeahead)
    // default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
    .use(VueCookies, { expires: '7d'})
    .use(auth)
    .use(FloatingVue)
    .use(store)
    /*.use(VueEcho, {
        broadcaster: 'pusher',
        wsHost:  'node2.tbc.bitdivision.de',
        wsPort:  '443',
        key: 'Haehnchen4',
        cluster: 'mt1',
        forceTLS: true
    })*/
    .use(VueToast,{
        position: 'bottom',
        duration: 6000,
    })
    .use(Toast, {
        shareAppContext: true,
        duration: 6000,
    })
    .use(debug);

app.mount('#app');
