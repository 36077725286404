<template>
    <CModal :visible="showModal" size="md" alignment="top" @close="showModal=false" backdrop="static">
        <CModalHeader>
            <CModalTitle>{{$t('user.changePassword')}}</CModalTitle>
        </CModalHeader>
        <CModalBody>

          <div class="alert alert-danger" v-if="passwordToOld">
              <div class="row align-items-center">
                <div class="col-auto"><i class="fa-solid fa-diamond-exclamation fa-3x mx-1"></i></div>
                <div class="col" v-html="$t('common.passwordToOld')"></div>
              </div>

          </div>

          <div class="mb-3">
              <input-field type="password" name="currentPassword" v-model="formData.currentPassword" :errors="errors" required :label="$t('user.currentPassword')"/>
          </div>
          <hr>
          <div class="mb-3">
            <input-field type="password" name="newPassword" v-model="formData.newPassword" :errors="errors" required :label="$t('user.newPassword')"/>
          </div>
          <div>
            <input-field type="password" name="newPasswordReply" v-model="formData.newPasswordReply" :errors="errors" required :label="$t('user.replyPassword')"/>
          </div>
        </CModalBody>
        <CModalFooter>
            <div class="col text-end">
                <button class="btn btn-outline-dark btn-sm me-3" @click="showModal=false">{{$t('common.btnCancel')}}</button>
                <button class="btn btn-primary btn-sm" @click="changePassword">{{$t('user.changePassword')}}</button>
            </div>
        </CModalFooter>
    </CModal>
</template>

<script>
import {
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from "@coreui/bootstrap-vue";
import InputField from "@/components/inputField.vue";
import moment from "moment";
export default {
    name: "modalChangePassword",
    components: {
      InputField,
        CModal, CModalBody,
        CModalFooter,
        CModalHeader,
        CModalTitle,
    },
    props: {
        shown: Boolean,
    },
    computed:{
        passwordToOld(){
          if(!this.$auth.check()) return false;

          if(!this.$auth.user().lastPasswordChange){
            return true;
          }

          let now = moment();
          let duration = moment.duration(now.diff(this.$auth.user().lastPasswordChange));
          let months = duration.asMonths();

          if(months > 6){
            return true;
          }

          return false;
        }
    },
    watch: {
        shown(value) {
            this.showModal = value;
        },
        showModal(value) {
            this.$emit('update:shown', value);

            this.formData = {
              currentPassword: '',
              newPassword: '',
              newPasswordReply: '',
            }

            this.errors = {};
        }
    },
    data() {
        return {
            showModal: false,
            errors: {},
            formData:{
              currentPassword: '',
              newPassword: '',
              newPasswordReply: '',
            }
        };
    },
    mounted() {
        this.showModal = this.shown;
    },
    methods:{
      changePassword(){
          let url = "auth/changePassword";

          this.axios.post(url, this.formData).then(res => {
              this.$toast.success(this.$t('user.msg.passwordChanged'));
              this.errors = {};
              this.showModal = false;
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
              this.$toast.error(this.$t('common.inputError'));
            }
            else{
              this.$toast.error(this.$t('common.error'));
            }

          });
      }
    }
}
</script>