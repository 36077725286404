<template>
  <div class="d-flex flex-column flex-shrink-0 bg-light border-end" style="width: 4.5rem;">
    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center flex-grow-1">
      <li class="nav-item">
        <router-link :to="{name:'home'}" active-class="active" class="nav-link py-3 border-bottom rounded-0">
          <i class="fa-regular fa-chart-line"></i>
        </router-link>
      </li>

      <li>
        <div class="btn-group dropend border-bottom" role="group" aria-label="Basic example">
          <router-link :to="{name:'crm'}" active-class="active" class="nav-link py-3 border-bottom rounded-0">
            <i class="fa-regular fa-chart-network"></i>
          </router-link>
          <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropend</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end text-small shadow" style="">
            <li><a class="dropdown-item" href="#">Neuen Kunden anlegen</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">ABC-Analyse</a></li>
          </ul>
        </div>
      </li>

      <li>
        <a href="#" class="nav-link py-3 border-bottom rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Orders" data-bs-original-title="Orders">
          <i class="fa-regular fa-bars-progress"></i>
        </a>
      </li>
      <li>
        <div class="btn-group dropend border-bottom" role="group" aria-label="Basic example">
          <a href="#" class="nav-link py-3  rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Products" data-bs-original-title="Products">
            <i class="fa-regular fa-file-invoice"></i>
          </a>
          <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropend</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end text-small shadow" style="">
            <li><a class="dropdown-item" href="#">New project...</a></li>
            <li><a class="dropdown-item" href="#">Settings</a></li>
            <li><a class="dropdown-item" href="#">Profile</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" @click="$auth.logout()" href="#">Abmelden</a></li>
          </ul>
        </div>
      </li>
      <li>
        <a href="#" class="nav-link py-3 border-bottom rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Customers" data-bs-original-title="Customers">
          <i class="fa-regular fa-building-columns"></i>
        </a>
      </li>
      <li>
        <a href="#" class="nav-link py-3 border-bottom rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Customers" data-bs-original-title="Customers">
          <i class="fa-regular fa-file-certificate"></i>
        </a>
        <router-link :to="{name:'articles'}" active-class="active" class="nav-link py-3 border-bottom rounded-0">
          <i class="fa-regular fa-file-certificate"></i>
        </router-link>
      </li>
      <li>
        <a href="#" class="nav-link py-3 border-bottom rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Customers" data-bs-original-title="Customers">
          <i class="fa-regular fa-chart-pie"></i>
        </a>
      </li>
    </ul>
    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
      <li>
        <a href="#" class="nav-link py-3 border-top rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Customers" data-bs-original-title="Customers" @click="debug.enableDebug()">
          <i class="fa-regular fa-bug"></i>
        </a>
      </li>
      <li>
        <a href="#" class="nav-link py-3 border-top rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Customers" data-bs-original-title="Customers">
          <i class="fa-regular fa-gear"></i>
        </a>
      </li>
    </ul>
    <!--
    <div class="dropdown border-top dropend">
      <a href="#" class="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <strong>DE</strong>
      </a>
      <ul class="dropdown-menu text-small shadow" style="">
        <li><a class="dropdown-item active" href="#">Deutsch</a></li>
        <li><a class="dropdown-item" href="#">English</a></li>
      </ul>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "menuSidebar",
  methods: "",
}
</script>

<style scoped>

</style>