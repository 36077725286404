<template>
  <CModal :visible="showModal" size="xl" alignment="top" @close="showModal=false" backdrop="static">
    <CModalHeader>
      <CModalTitle>{{$t('login.2fa')}}</CModalTitle>
    </CModalHeader>
    <CModalBody>

      <div class="row">
        <div class="col" v-html="$t('login.2fa_activation')" />
        <div class="col border bg-light pt-3">
          <b v-html="$t('login.2fa_headline')" />
          <div class="row mt-3" v-if="!loadingCode">
            <div class="col-auto">
              <div v-html="qr" class="border d-inline-block" />
            </div>
            <div class="col">
              <p v-html="$t('login.2fa_firstCode')" />
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-shield-halved fa-fw text-primary"></i></span>
                <template v-for="(n, index) in formData.twoFA.length" :key="index">
                  <input type="text" class="form-control text-center" maxlength="1" placeholder="" v-model="formData.twoFA[index]" :disabled="loading" name="password" ref="twofa" :id="'twofa'+index" @input="checkInput(index)">
                </template>
              </div>
              <div class="d-grid gap-2">
                <button class="btn btn-primary" @click="enable2FA()" :disabled="!codeComplete || loading"><i class="fa-solid fa-spinner-third fa-fw fa-spin" v-if="loading"></i> {{$t('login.2fa_enable')}}</button>
              </div>
              <p class="text-muted small mt-3" v-html="$t('login.2fa_hints')" />
            </div>

          </div>
          <div v-else class="text-center">
            <i class="fa-solid fa-spinner-third fa-fw fa-spin mt-5 fa-3x"></i>
          </div>
        </div>
      </div>


    </CModalBody>
    <CModalFooter>
      <div class="col text-end">
        <button class="btn btn-outline-dark btn-sm" @click="showModal=false">{{$t('common.btnCancel')}}</button>
      </div>
    </CModalFooter>
  </CModal>
</template>

<script>
import {
  CModal, CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/bootstrap-vue";
import InputField from "@/components/inputField.vue";
import moment from "moment";
export default {
  name: "modalEnable2Fa",
  components: {
    InputField,
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
  },
  props: {
    shown: Boolean,
  },
  computed:{
      codeComplete(){
          return this.formData.twoFA.join('').length == this.formData.twoFA.length;
      }
  },
  watch: {
    shown(value) {
      this.showModal = value;
    },
    showModal(value) {
      this.$emit('update:shown', value);

      if(value == true){
        this.formData = {
          twoFA: Array(6),
          secret: null,
        }

        this.init();

        this.errors = {};
      }
    }
  },
  data() {
    return {
      showModal: false,
      loadingCode: true,
      errors: {},
      qr: null,
      formData:{
        twoFA: Array(6),
        secret: null,
      }
    };
  },
  mounted() {
    this.showModal = this.shown;
  },
  methods:{
    init(){
        let url = "auth/create2FA";
        this.loadingCode = true;

        this.axios.get(url).then(res => {
            this.qr = res.data.qrImage;
            this.formData.secret = res.data.secret;
            this.loadingCode = false;
        });
    },
    enable2FA(){
        let url = "auth/enable2FA";

        this.axios.post(url, {
          secret: this.formData.secret,
          two_fa_code: this.formData.twoFA.join('')
        })
        .then(res => {
            if(res.data.success == true){
                this.$toast.success(this.$t('login.2fa_enabled'));
                this.showModal = false;
            }
            else{
              this.$toast.error(this.$t('login.errors.2faFailed'));
              this.formData.twoFA = Array(6);

              setTimeout(() => {
                this.$refs.twofa[0].focus();
              }, 300);
            }
        })
        .catch(error => {
          this.$toast.error(this.$t('login.errors.2faFailed'));
          this.formData.twoFA = Array(6);

          setTimeout(() => {
            this.$refs.twofa[0].focus();
          }, 300);
        });
    },
    checkInput(index) {
      this.formData.twoFA[index] = this.formData.twoFA[index].replace(/[^0-9]/g, '');

      if(this.formData.twoFA[index] && this.formData.twoFA.length-1 > index){
        this.$refs.twofa[index+1].focus();
      }
    },
  }
}
</script>