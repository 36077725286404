<template>
    <div id="crm" class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-3 col-xxl-2 h-100 bg-secondary text-white  d-flex flex-column">
                <div class="flex-grow-1 p-3">
                    <div class="mb-3">
                        <strong class="label">{{ $t('company.company') }}</strong>
                        <input class="form-control" v-model="formData.term" @keyup="load" :placeholder="$t('common.search')"/>
                    </div>
                    <div class="mb-3">
                        <strong class="label">{{ $t('company.customerType') }}</strong>
                        <select class="form-select" v-model="formData.type" @change="load">
                            <option :value="null">{{ $t('common.all') }}</option>
                            <option v-for="type in $store.state.customerTypes" :key="type.id" :value="type.id">{{ type.translated }}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <strong class="label">{{ $t('address.country') }}</strong>
                        <select class="form-select" v-model="formData.country" @change="load">
                            <option :value="null">{{ $t('common.all') }}</option>
                            <option v-for="country in $store.state.countries"  :key="country.id" :value="country.country_code_iso">{{ country.translated }}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                      <strong class="label">{{ $t('address.zipCity') }}</strong>
                      <input class="form-control" v-model="formData.city" @keyup="load" :placeholder="$t('address.zipCity')"/>
                    </div>
                    <div class="mb-3" v-if="$auth.user().userrole_id != 3">
                        <strong class="label">{{ $t('common.salesManager') }}</strong>
                        <select class="form-select" v-model="formData.salesManager" @change="load">
                            <optgroup label="Optionen">
                                <option :value="null">{{ $t('common.all') }}</option>
                                <option :value="0">{{ $t('company.customerWithoutFlagging') }}</option>
                            </optgroup>
                            <optgroup label="Sales-Manager">
                                <option v-for="user in $store.state.users" :key="user.id" :value="user.id">{{ user.name }}</option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="mb-3" v-else>
                      <strong class="label">{{ $t('common.salesManager') }}</strong>
                      <select class="form-select" v-model="formData.salesManager" @change="load">
                        <optgroup label="Optionen">
                          <option :value="null">{{ $t('common.all') }}</option>
                          <option :value="0">{{ $t('company.customerWithoutFlagging') }}</option>
                        </optgroup>
                        <optgroup label="Sales-Manager">
                          <template v-for="user in $store.state.users" :key="user.id" >
                          <option :value="user.id" v-if="$auth.user().id == user.id">{{ user.name }}</option>
                          </template>
                        </optgroup>
                      </select>
                    </div>
                  <div class="mb-3">
                    <strong class="label">{{ $t('common.customerStatus') }}</strong>
                    <select class="form-select" @change="load" v-model="formData.status">
                      <option :value="null">{{ $t('common.all') }}</option>
                      <option value="lead">{{ $t('common.lead') }}</option>
                      <option value="newCustomer">{{ $t('common.newCustomer') }}</option>
                      <option value="existingCustomer">{{ $t('common.existingCustomer') }}</option>
                    </select>
                  </div>
                    <div class="mb-3">
                        <strong class="label">{{ $t('company.branch') }}</strong>
                        <select class="form-select" @change="load" v-model="formData.branch">
                            <option :value="null">{{ $t('company.allBranches') }}</option>
                            <option v-for="branch in $store.state.branches" :key="branch.id" :value="branch.id">{{ branch.translated }}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                      <strong class="label">ABC-Klassifizierung</strong>
                      <select class="form-select" @change="load" v-model="formData.prioritySalesManager">
                        <option :value="null">{{ $t('common.all') }}</option>
                        <option :value="3">A-Kunden</option>
                        <option :value="2">B-Kunden</option>
                        <option :value="1">C-Kunden</option>
                      </select>
                    </div>
                    <div class="form-check" >
                        <input class="form-check-input" v-model="formData.disabled" type="checkbox" id="flagIsDeactivated" @change="load">
                        <label class="form-check-label" for="flagIsDeactivated">
                            {{$t('company.onlyBlockedUsers')}}
                        </label>
                    </div>
                    <div class="form-check" >
                      <input class="form-check-input" v-model="formData.active" type="checkbox" id="flagIsActivated" @change="load">
                      <label class="form-check-label" for="flagIsActivated">
                        {{$t('company.onlyActive')}}
                      </label>
                    </div>
                </div>
                <div class="btn-group m-3">
                    <button class="btn btn-danger" @click="reset()"><i class="fa-solid fa-broom"></i> {{$t('common.clear')}}</button>
                    <div class="btn-group dropup" role="group">
                        <button type="button" class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-solid fa-circle-ellipsis-vertical"></i>
                        </button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" @click="$root.createCompany()"><i class="fa-solid fa-plus fa-fw"></i> neue Firma hinzufügen</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col h-100 d-flex flex-column p-2 overflow-hidden">
                <div class="card h-100">
                    <div class="card-header text-bold">{{ $t('company.companies') }}</div>
                    <perfect-scrollbar class="h-100" :disable="true">
                    <div class="card-body p-0">
                        <data-view
                            :loading="loading"
                            :fields="fields"
                            :data="data"
                            v-model:sort-by="formData.sortBy"
                            v-model:sort-desc="formData.sortDesc"
                            @rowClick="goto"
                            @rowRightClick="gotoBlank"
                        >
                        <template #cell(prioritySalesManager)="row">
                          <b v-if="row.value == 3">A</B>
                          <b v-else-if="row.value == 2">B</B>
                          <b v-else>C</B>
                        </template>
                        <template #cell(status)="row">
                          <i class="fa-solid fa-circle-xmark text-danger" title="Deaktiviert" v-if="!row.row.isActive"></i>
                          <i class="fa-solid fa-octagon-exclamation text-danger" title="Liefersperre" v-else-if="row.row.isDeliveryBlocked"></i>
                          <i class="fa-solid fa-circle-xmark text-danger" v-else-if="row.row.isDeactivated"></i>
                          <i class="fa-solid fa-circle-check text-success" v-else></i>
                        </template>
                          <template #cell(customertype-name)="row">
                            <template v-if="row.row.customertype">
                              {{row.row.customertype.translated}}
                            </template>
                          </template>
                        <template #cell(country-name)="row">
                          <template v-if="row.row.country">
                            {{row.row.country.translated}}
                          </template>
                        </template>
                        <template #cell(lastOrderDate)="row">
                          <template v-if="row.value">
                            {{$filters.date(row.value, "DD.MM.YY")}}
                          </template>
                        </template>
                        <template #cell(trend)="row">
                          <i class="fa-solid fa-arrow-up-right text-success" v-if="row.value > 0"></i>
                          <i class="fa-solid fa-arrow-down-right text-danger" v-else-if="row.value < 0"></i>
                          <i class="fa-solid fa-equals text-warning" v-else></i>

                        </template>
                        <template #cell(branch-branch_name)="row">
                            <template v-if="row.row.branch">
                              {{row.row.branch.translated}}
                            </template>
                        </template>
                        </data-view>
                    </div>
                    </perfect-scrollbar>
                    <div class="card-footer">
                        <div class="row align-items-center">
                        <div class="col-auto" v-if="dataView.current_page">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button class="btn btn-primary btn-sm" :disabled="dataView.current_page == 1" @click="firstPage()"><i class="fa-solid fa-arrow-left-from-line"></i></button>
                                <button class="btn btn-primary btn-sm" :disabled="dataView.current_page == 1" @click="prevPage()"><i class="fa-solid fa-arrow-left" ></i></button>
                                <select class="form-select form-select-sm"  v-model="formData.page" @change="setPage(formData.page)">
                                    <option v-for="n in dataView.last_page" :value="n" :key="n">{{ n }}</option>
                                </select>
                                <button class="btn btn-primary btn-sm" :disabled="dataView.current_page >= dataView.last_page" @click="nextPage()"><i class="fa-solid fa-arrow-right"></i></button>
                                <button class="btn btn-primary btn-sm" :disabled="dataView.current_page >= dataView.last_page" @click="lastPage()"><i class="fa-solid fa-arrow-right-from-line"></i></button>
                            </div>
                        </div>
                        <div class="col text-center" v-if="dataView.total">{{dataView.from}}-{{dataView.to}} / {{dataView.total}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dataView from "@/components/dataView.vue";
import eventBus from "@/eventBus";
import axios from "axios";

export default {
    name: "crmSearch",
    props:{
        returnCompany: {
            type: Boolean,
            required: false,
            default(){
                return false;
            }
        },
    },
    emits:['companySelected'],
    components: {dataView},
    watch: {
        'formData.sortDesc'(){
            this.load();
        },
        'formData.sortBy'(){
            this.load();
        },
        '$i18n.locale'(locale){
          this.load();
        }
    },
    computed:{
      fields(){
        return [
          {key: 'prioritySalesManager', label: '<i class="fa-solid fa-star"></i>', thClass: "col-auto text-center", tdClass: ' col-auto text-center', sortable: true },
          {key: 'trend', label: "Trend", thClass: "col-auto text-center", tdClass: ' col-auto text-center', sortable: false },
          {key: 'status', label: this.$t('common.status'), thClass: "col-auto text-center", tdClass: ' col-auto text-center', sortable: false },
          //{key: 'trend', label: this.$t('company.trend'), thClass: "col-auto", tdClass: ' col-auto text-center', sortable: true },
          {key: 'crm_id', label: 'UID1', thClass: "col-1", tdClass: 'text-end col-1', sortable: true },
          {key: 'name', label: this.$t('company.company'), thClass: "col-6", tdClass: 'col-6', sortable: true },
          {key: 'customertype.name', label: this.$t('company.customerType'), thClass: "col-1", tdClass: 'col-1', sortable: true },
          {key: 'address.zipCode', label: this.$t('address.zipcode'), thClass: "col-auto", tdClass: 'col-auto', sortable: true },
          {key: 'address.city', label: this.$t('address.city'), thClass: "col-2", tdClass: 'col-2', sortable: true },
          {key: 'country_id', label: this.$t('address.country'), thClass: "col-auto", tdClass: 'col-auto', sortable: true },
          {key: 'branch.branch_name', label: this.$t('company.branch'), thClass: "col-2", tdClass: ' col-2', sortable: true },
          {key: 'lastOrderDate', label: this.$t('company.lastOrder'), thClass: "col-auto", tdClass: ' col-auto', sortable: true },
        ];
      },
      data(){
        if(this.dataView.data === undefined){
          return [];
        }

        let data = this.dataView.data;

        for(let item of data){
          if(!item.isActive){
            item._rowVariant = "danger";
          }
        }

        return data;
      },
    },
    data(){
        return {
            formData: {
            },
            loading: false,
            searchDefault:{
                term: '',
                active: true,
                type: null,
                country: null,
                city: null,
                status: null,
                salesManager: null,
                branch: null,
                disabled: false,
                sortBy: 'name',
                sortDesc: false,
                prioritySalesManager: null,
                page: 1,
            },
            dataView: { }
        }
    },
    beforeMount() {
        let settings = this.$cookies.get('settingsCrmCompanySearch');


        if(settings){
            this.formData = {...this.searchDefault, ...settings};
        }
        else{
            this.formData = {...this.searchDefault};
        }
    },
    mounted() {
        this.load();

        eventBus.$on('movePrev', this.prevPage);
        eventBus.$on('moveNext', this.nextPage);
    },
    unmounted() {
        eventBus.$off('movePrev');
        eventBus.$off('movePrev');
    },
    methods:{
        reset(){
            this.formData = {...this.searchDefault};
            this.load();
        },
        load(){
            if(this.loading) return;

            let url = "crm/companies/find";

            this.$cookies.set('settingsCrmCompanySearch', JSON.stringify(this.formData));
            this.loading = true;
            this.axios.post(url, this.formData).then(res => {
                this.dataView = res.data;

                if(this.dataView.current_page > this.dataView.last_page){
                    this.formData.page = 1;
                    this.load();
                }

                this.loading = false;
            }).catch(error => {
                this.loading = false;
                this.$toast.error(this.$t('common.error'));
            });
        },
        setPage(value){
            this.formData.page = value;
            this.load();
        },
        firstPage(){
            this.formData.page = 1;
            this.load();
        },
        lastPage(){
            this.formData.page = this.dataView.last_page;
            this.load();
        },
        prevPage(){
            if(this.formData.page <= 1) return 0;

            this.formData.page -= 1;
            this.load();
        },
        nextPage(){
            if(this.dataView.current_page >= this.dataView.last_page) return 0;

            this.formData.page += 1;
            this.load();
        },
        goto(row){
            if(this.returnCompany == true){
                this.$emit('companySelected', {...row});
            }
            else{
             this.$router.push({name: 'company', params: {id : row.id}});
            }
        },
        gotoBlank(row){
          let url = this.$router.resolve({name: 'company', params: {id : row.id}});
          window.open(url.href, '_blank');
        }
    }
}
</script>

<style scoped>

</style>