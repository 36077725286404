<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "comment",
  props:{
    comment: Object,
  }
})
</script>

<template>
    <div class="comment card p-0 mb-2" :class="[comment.user_id == $auth.user().id ? 'bg-secondary text-white ms-5' : 'me-5']">
        <div class="card-body p-2" v-html="comment.content"></div>
        <div class="card-footer p-1">
        <div class="row">
            <div class="col small" v-if="comment.user" >{{comment.user.name}}</div>
            <div class="col small text-end">
                {{$filters.date(comment.created_at)}}
            </div>
        </div>
        </div>
    </div>
</template>

<style scoped>

</style>