<script>
export default {
  name: "menuLogin"
}
</script>

<template>
  <div class="dropdown border-start">
    <a href="#" class="d-flex align-items-center justify-content-center px-2 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      Login
    </a>
    <ul class="dropdown-menu dropdown-menu-end text-small shadow" >
      <template v-for="user in $store.state.users" :key="user.id">
        <li>
          <a class="dropdown-item" href="#" @click="$root.loginUser(user)">
            <div class="row align-items-center flex-nowrap">
              <div class="col-auto"><div style="width: 28px; height: 28px; background: transparent no-repeat top center; background-size: cover; border-width: 3px !important;" :style="{'background-image': 'url(' + $filters.image(user.profilImage, 'thumb') + ')'}"  :class="[$online.isOnline(user)? 'border-success' : 'border-light']" class="d-block rounded-circle border bg-white shadow-sm"></div></div>
              <div class="col">{{user.name}}</div>
            </div>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<style scoped>

</style>