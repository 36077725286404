import {createRouter, createWebHistory} from 'vue-router';

function loadView(view) {
    return () => import(`@/pages/${view}.vue`);
}

const router = createRouter({
    hashbang: false,
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: loadView("home/dashboardAdmin"),
            meta: {
                auth: true,
                closeable: false,
            }
        },
        {
            path: '/articles',
            name: 'articles',
            component: loadView("article/entryArticle"),
            meta: {
                auth: true,
                tab: false
            }
        },
        {
            path: '/crm',
            name: 'crm',
            component: loadView("crm/entryCrm"),
            meta: {
                auth: true,
                tab: false
            }
        },
        {
            path: '/crm/abc',
            name: 'abc',
            component: loadView("crm/SalesManagerABC"),
            meta: {
                auth: true,
                tab: false
            }
        },
        {
            path: '/login',
            name: 'login',
            component: loadView("auth/formLogin"),
            meta: {
                auth: false,
                tab: false
            }
        },
        {
            path: '/crm',
            name: 'crm',
            component: loadView("crm/crmSearch"),
            meta: {
                auth:{
                    roles: ['searchCompany'],
                    rolesKey: 'roles.crm'
                },
            },
        },
        {
            path: '/crm/kontakte',
            name: 'crmContacts',
            component: loadView("crm/crmSearchContacts"),
            meta: {
                auth:{
                    roles: ['searchContacts'],
                    rolesKey: 'roles.crm'
                },
                //auth: true,
            },
        },
        {
            path: '/crm/shopusers',
            name: 'crmShopUsers',
            component: loadView("crm/crmSearchShopUser"),
            meta: {
                auth:{
                    roles: ['searchContacts'],
                    rolesKey: 'roles.crm'
                },
                //auth: true,
            },
        },
        {
            path: '/crm/shopuser/:userId',
            name: 'crmShopUser',
            component: loadView("crm/ShopUser"),
            meta: {
                auth:{
                    roles: ['searchShopusers'],
                    rolesKey: 'roles.crm'
                },
                //auth: true,
            },
        },
        {
            path: '/crm/flagging',
            name: 'flagging',
            component: loadView("crm/editFlagging"),
            meta: {
                auth:{
                    roles: ['editFlagging'],
                    rolesKey: 'roles.crm'
                },
                //auth: true,
            },
        },
        {
            path: '/crm/:id',
            name: 'company',
            component: loadView("crm/CompanyDetails"),
            meta: {
                auth: true,
                tab: false
            }
        },
        {
            path: '/crm/kontakt/:contactId',
            name: 'contact',
            component: loadView("crm/CompanyContact"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/crm/adresse/:addressId',
            name: 'address',
            component: loadView("crm/CompanyAddress"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/artikel/',
            name: 'articles',
            component: loadView("article/articleSearch"),
            meta: {
                auth: true,
                tab: false
            },
            props: route => ({ modus: route.query.modus })
        },
        {
            path: '/artikel/bestellung/:id',
            name: 'articleOrder',
            component: loadView("article/articleOrder"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/artikel/bestellung',
            name: 'articleOrders',
            component: loadView("article/articleOrders"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/artikel/:id',
            name: 'article',
            component: loadView("article/articleDetail"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/angebot/neu',
            name: 'createOffer',
            component: loadView("offer/CreateOffer"),
            meta: {
                auth:{
                    roles: ['editFlagging'],
                    rolesKey: 'roles.crm'
                },
                tab: false
            },
        },
        {
            path: '/angebot/:id',
            name: 'offer',
            component: loadView("offer/CreateOffer"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/angebote',
            name: 'offers',
            component: loadView("offer/offerSearch"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/angebotsfreigabe',
            name: 'releaseOffers',
            component: loadView("offer/releaseOffers"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/angebotsfreigabe/archiv',
            name: 'releaseOfferOverview',
            component: loadView("offer/releaseOfferOverview"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/berichte/mitarbeiter/:id',
            name: 'employerReport',
            component: loadView("reports/employerReport"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/dev',
            name: 'dev',
            component: loadView("dev/devPage"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/users',
            name: 'users',
            component: loadView("settings/SettingsUser"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/403',
            name: 'forbidden',
            component: loadView("errors/forbidden"),
            meta: {
                auth: true,
                tab: false
            },
        },
        {
            path: '/account',
            name: 'account',
            component: loadView("fibu/AccountView"),
            meta: {
                auth:{
                    roles: ['showAccount'],
                    rolesKey: 'roles.fibu'
                },
                tab: false
            },
        },
        {
            path: '/rechungen',
            name: 'invoices',
            component: loadView("fibu/searchInvoice"),
            meta: {
                auth:{
                    roles: ['showAccount'],
                    rolesKey: 'roles.fibu'
                },
                tab: false
            },
        },
        {
            path: '/rechung/:id',
            name: 'invoice',
            component: loadView("fibu/invoice"),
            meta: {
                auth:{
                    roles: ['showAccount'],
                    rolesKey: 'roles.fibu'
                },
                tab: false
            },
        },
        {
            path: '/auftragsverarbeitung',
            name: 'orders',
            component: loadView("order/orderOverview"),
            meta: {
                auth:{
                    roles: ['processOrders'],
                    rolesKey: 'roles.erp'
                },
                tab: false
            },
            props: route => ({ modus: route.query.modus })
        },
        {
            path: '/archiv',
            name: 'archive',
            component: loadView("history/searchOrderHistory"),
            meta: {
                auth:{
                    roles: ['processOrders'],
                    rolesKey: 'roles.erp'
                },
                tab: false
            },
        },
        {
            path: '/archiv/:id',
            name: 'historyOrder',
            component: loadView("history/OrderHistory"),
            meta: {
                auth:{
                    roles: ['processOrders'],
                    rolesKey: 'roles.erp'
                },
                tab: false
            },
        },
        {
            path: '/auftrag/:id',
            name: 'order',
            component: loadView("order/Order"),
            meta: {
                auth:{
                    roles: ['processOrders'],
                    rolesKey: 'roles.erp'
                },
                tab: false
            },
        }

    ]
});

export default (app) => {
    app.router = router;
    app.use(router);
}