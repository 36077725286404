<template>
  <div class="card h-100 shadow-sm" style="height: 100%; overflow: auto;">
    <div class="card-header text-white bg-secondary py-1 pe-2">
        <div class="row align-items-center">
          <div class="col">
              <i class="fa-solid fa-file-signature text-primary me-2"></i>
              <strong>{{ $t('widgets.activeOrders.title', {weeks: 6}) }}</strong>
          </div>
          <div class="col-auto">
              <div class="input-group">
                  <div class="input-group-text"><i class="fa-solid fa-magnifying-glass text-primary"></i></div>
                  <input class="form-control form-control-sm" v-model="formData.term" :placeholder="$t('common.search') ">
              </div>
          </div>
        </div>
    </div>
    <perfect-scrollbar class="h-100">
    <div class="card-body p-0">
      <data-view
          :fields="fields"
          :data="data"
          v-model:sort-by="formData.sortBy"
          v-model:sort-desc="formData.sortDesc"
          @rowClick="goto"
      >

        <template #cell(icon)="row">
          <i :title="title[row.row.status]" :class="icon[row.row.status]" class="fa-fw "></i>
        </template>
          <template #cell(offerDate)="row">
            <span v-if="row.value">
              {{$filters.date(row.value)}}
              </span>
          </template>
        <template #cell(number)="row">
          {{row.row.numberPrefix}}{{row.value}}
        </template>statusName
        <template #cell(totalNet)="row">
            {{$filters.formatCurrency(row.value)}}
        </template>
        <template #cell(status)="row">
          {{$t(statusName(row.value))}}
        </template>
      </data-view>
    </div>
    </perfect-scrollbar>
    <div class="card-footer pt-0 ps-0 overflow-hidden">
        <div class="row align-items-center">
            <div class="col-auto" v-if="dataView.current_page">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-primary btn-sm" :disabled="dataView.current_page == 1" @click="firstPage()"><i class="fa-solid fa-arrow-left-from-line"></i></button>
                    <button class="btn btn-primary btn-sm" :disabled="dataView.current_page == 1" @click="prevPage()"><i class="fa-solid fa-arrow-left" ></i></button>
                    <select class="form-select form-select-sm"  v-model="formData.page" @change="setPage(formData.page)">
                        <option v-for="n in dataView.last_page" :value="n" :key="n">{{ n }}</option>
                    </select>
                    <button class="btn btn-primary btn-sm" :disabled="dataView.current_page >= dataView.last_page" @click="nextPage()"><i class="fa-solid fa-arrow-right"></i></button>
                    <button class="btn btn-primary btn-sm" :disabled="dataView.current_page >= dataView.last_page" @click="lastPage()"><i class="fa-solid fa-arrow-right-from-line"></i></button>
                </div>
            </div>
            <div class="col text-center" v-if="dataView.total !== undefined">{{dataView.from}}-{{dataView.to}} / {{dataView.total}}</div>
            <div class="col-auto text-end">
                  {{$filters.formatCurrency(dataView.totalNetSum)}}
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dataView from "@/components/dataView.vue";
import {getStatusName} from "@/helper";

export default {
  name: "openOffers",
  components: {
    dataView
  },
  props:{
    contact: Object,
  },
  watch: {
      'formData.sortDesc'(){
          this.load();
      },
      'formData.sortBy'(){
          this.load();
      },
      'formData.term'(){
          if(this.searchDelay) {
              clearTimeout(this.searchDelay);
          }
          this.searchDelay = setTimeout(() => {
              this.load();
          }, 300);
      }
  },
  computed:{
    fields() {
      return [
        {key: 'icon', label: '#', thClass: "col-auto", tdClass: 'text-center col-auto', sortable: false },
        {key: 'offerDate', label: this.$t('common.date'), thClass: "col-1", tdClass: 'text-end col-1', sortable: false},
        {key: 'number', label: this.$t('offer.operationNumbeShort'), thClass: "col-1", tdClass: 'text-end col-1', sortable: false},
        {key: 'company.name', label: this.$t('company.company'), thClass: "col-4", tdClass: 'col-4', sortable: false},
        {key: 'contact.firstName', label: this.$t('contact.firstName'), thClass: "col-2", tdClass: 'col-2', sortable: false},
        {key: 'contact.lastName', label: this.$t('contact.lastName'), thClass: "col-2", tdClass: 'col-2', sortable: false},
        {key: 'status', label: this.$t('common.status'), thClass: "col-2", tdClass: 'col-2', sortable: false},
        {key: 'keywords', label: "Info", thClass: "col-2", tdClass: 'col-2', sortable: false},
        {key: 'totalNet', label: this.$t('offer.total'), thClass: "col-1", tdClass: 'text-end col-1', sortable: false},
      ];
    },
    data(){
      if(this.dataView.data === undefined){
        return [];
      }

      let data = this.dataView.data;

      for(let item of data){
        if(item.status == 'draft'){
          item._rowVariant = "info";
        }
        if(item.status == 'waitingForRelease'){
          item._rowVariant = "warning";
        }
        if(item.status == 'released'){
          item._rowVariant = "success";
        }
        if(item.status == 'rejected'){
          item._rowVariant = "danger";
        }
      }

      return data;
    }
  },
  data(){
    return {
        /*fields: [
          {key: 'sales_offer_date', label: 'Datum', thClass: "col-1", tdClass: 'text-end col-1', sortable: true },
          {key: 'sales_offer_number', label: 'Angebots-Nr.', thClass: "col-1", tdClass: 'text-end col-1', sortable: true },
          {key: 'company.name', label: 'Firma', thClass: "col-4", tdClass: 'col-4', sortable: true },
          {key: 'customer.firstName', label: 'Vorname', thClass: "col-2", tdClass: 'col-2', sortable: true },
          {key: 'customer.lastName', label: 'Nachname', thClass: "col-2", tdClass: 'col-2', sortable: true },
          {key: 'sales_offer_status_name', label: 'Status', thClass: "col-1", tdClass: 'col-1', sortable: true },
          {key: 'total', label: 'Gesamt', thClass: "col-1", tdClass: 'text-end col-1', sortable: true },
        ],*/
        formData: {
        },
        loading: false,
        searchDefault:{
            term: '',
            contact: '',
            type: null,
            store: null,
            sortBy: 'sales_offer_date',
            sortDesc: true,
            page: 1,
        },
        dataView: {
        },
      icon:{
        'draft': 'fa-solid fa-sparkles text-danger',
        'created': 'fa-solid fa-plus-circle text-warning',
        'sendToCustomer': 'fa-solid fa-envelope text-success',
        'waitingForRelease': 'fa-solid fa-hourglass-half text-warning',
        'released': 'fa-solid fa-thumbs-up text-success',
        'rejected': 'fa-solid fa-thumbs-down text-danger',
        'cancelled': 'fa-solid fa-trash text-danger',

        'embodiment': '',
      },
      title: {
        'create': "Auftrag wurde erstellt",
        'delivered': 'Ware ausgeliefert',
        'waiting': 'Auftrag wartet auf Verarbeitung',
        'waitingForPayment': "Wartet auf Zahlungseingang",
        'waitingForArticle': "Warenbestand fehlt",
        'waitingForKey': "Artikelposition wartet auf Key",
        'waitingForDisk': "MediaKit muss ausgeliefert werden",
        'ordered': 'Ware bei Muttergesellschaft bestellt',
      },
    }
  },
  mounted() {
      this.formData = {...this.searchDefault};

      this.load();
  },
  methods:{
      statusName(status){
        return getStatusName(status);
      },
      load(){
        let url = 'erp/offers/activeOffers';

        axios.post(url, this.formData).then(res => {
            this.dataView = res.data;

            if(this.dataView.current_page > this.dataView.last_page){
                this.formData.page = 1;
                this.load();
            }

            this.loading = false;
        });
      },
      setPage(value){
          this.formData.page = value;
          this.load();
      },
      firstPage(){
          this.formData.page = 1;
          this.load();
      },
      lastPage(){
          this.formData.page = this.dataView.last_page;
          this.load();
      },
      prevPage(){
          if(this.formData.page <= 1) return 0;

          this.formData.page -= 1;
          this.load();
      },
      nextPage(){
          if(this.dataView.current_page >= this.dataView.last_page) return 0;

          this.formData.page += 1;
          this.load();
      },
      goto(row){
          this.$router.push({name: 'offer', params: {id : row.id}});
      }

  }
}
</script>

<style scoped>

</style>