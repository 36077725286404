<script>
import {defineComponent} from 'vue'
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
import Comment from "@/pages/common/comment.vue";
import eventBus from "@/eventBus";

export default defineComponent({
  name: "modalComment",
  components: {
    Comment,
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
  },
  props: {
    shown: Boolean,
    modus: Object,
  },
  emits:['updateShown'],
  watch: {
    shown(value) {
      if(value === false) {
        this.reset();
      }
      else{
        this.$root.setModalHidden(false);
        this.getComments();
      }
      this.showModal = value;
    },
    showModal(value) {
      this.$emit('update:shown', value);
    }
  },
  data() {
    return {
      showModal: false,
      comment: '',
      comments: [],
    };
  },
  mounted() {
    this.showModal = this.shown;
    this.getComments();
  },
  methods: {
    setComment(){
        let url = "/comments";

        if(!this.modus || !this.modus.type ) return;

        this.axios.post(url, {modus: this.modus, comment: this.comment}).then(res => {
          this.comments = res.data.comments;
          eventBus.$emit('comment.new', {count: this.comments.length});
          this.comment = '';
        });
    },
    getComments(){
      let url = "/comments";

      if(!this.modus || !this.modus.type ) return;

      this.axios.get(url, {params: {modus: this.modus}}).then(res => {
        this.comments = res.data.comments;
      });
    },
    load(){

    },
    reset(){
        this.comment = '';
        this.comments = [];
    }
  }
})
</script>

<template>
  <CModal :visible="showModal" size="lg" alignment="top" @close="showModal=false" backdrop="static">
    <CModalHeader>
      <CModalTitle>Kommentare</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="row mb-3">
        <div class="col">
          <div class="card">
            <div class="card-header"><b>neuen Kommentar hinzufügen</b></div>
            <div class="card-body p-1">
              <textarea v-model="comment" class="form-control"></textarea>
            </div>
            <div class="card-footer text-end">
              <button class="btn btn-primary btn-sm" :disabled="!comment" @click="setComment()">Kommentar hinzufügen</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <template v-for="comment in comments" :key="comment.id">
            <comment :comment="comment" />
          </template>
        </div>
      </div>
    </CModalBody>
    <CModalFooter>
      <div class="col text-end">
        <button class="btn btn-outline-dark btn-sm me-3" @click="showModal=false">{{ $t('common.btnClose') }}</button>
      </div>
    </CModalFooter>
  </CModal>
</template>

<style scoped>

</style>