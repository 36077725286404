<script>
export default {
  name: "menuPhone",
  methods: {
    getClass(call){
       let $class = 'fa-phone ';

       if(call.direction == 'in') $class = 'fa-phone-arrow-right ';

       if(call.intern && this.$auth.user().id == call.user_id) $class = 'fa-phone ';
       if(call.intern && this.$auth.user().id == call.interUser_id) $class = 'fa-phone-arrow-right ';

       if(call.eventHungUpType == 'missed') $class = 'fa-phone-missed ';
       if(call.eventHungUpType == 'canceled') $class = 'fa-phone-missed ';
       if(call.eventHungUpType == 'busy') $class = 'fa-phone-missed ';

       if(call.eventHungUpType == 'accepted') $class += ' text-success';
       if(call.eventHungUpType == 'canceled') $class += ' text-info';
       if(call.eventHungUpType == 'busy') $class += ' text-danger';
       if(call.eventHungUpType == 'missed') $class += ' text-danger';
       if(call.eventHungUpType == 'canceled') $class += ' text-danger';

       return $class;
    }
  }
}
</script>

<template>
  <div class="dropdown border-start">
    <a href="#" class="d-flex align-items-center justify-content-center px-2 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa-solid fa-phone"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-end text-small shadow py-0 overflow-hidden"  style="min-width: 30vw;">
      <div class="row">
        <div class="col-7 pe-0">
          <div class="small text-muted p-2" v-if="$store.state.phone.length == 0">Keine Anrufe vorhanden</div>
          <ul class="list-group list-group-flush" v-else>
            <template v-for="call in $store.state.phone" :key="call.id">
              <li class="list-group-item list-group-item-action" :title="call.call_id">
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto">
                    <i class="fa-solid" :class="getClass(call)"></i>
                  </div>
                  <template v-if="call.intern">
                    <div class="col text-end" v-if="call.user_id != $auth.user().id">{{call.from}}</div>
                    <div class="col text-end" v-else>{{call.to}}</div>
                  </template>
                  <template v-else>
                    <div class="col text-end" v-if="call.direction == 'in'">{{call.from}}</div>
                    <div class="col text-end" v-else>{{call.to}}</div>
                  </template>
                </div>
                <div class="row align-items-center flex-nowrap" v-if="call.intern && call.user_id == $auth.user().id && call.intern_user">
                  <div class="col">{{call.intern_user.name}}</div>
                  <div class="col-auto"><div style="width: 28px; height: 28px; background: transparent no-repeat top center; background-size: cover; border-width: 3px !important;" :style="{'background-image': 'url(' + $filters.image(call.intern_user.profilImage, 'thumb') + ')'}"  :class="[$online.isOnline(call.intern_user)? 'border-success' : 'border-light']" class="d-block rounded-circle border bg-white shadow-sm"></div></div>
                </div>
                <div class="row align-items-center flex-nowrap" v-else-if="call.intern && call.internUser_id == $auth.user().id && call.user">
                  <div class="col">{{call.user.name}}</div>
                  <div class="col-auto"><div style="width: 28px; height: 28px; background: transparent no-repeat top center; background-size: cover; border-width: 3px !important;" :style="{'background-image': 'url(' + $filters.image(call.user.profilImage, 'thumb') + ')'}"  :class="[$online.isOnline(call.user)? 'border-success' : 'border-light']" class="d-block rounded-circle border bg-white shadow-sm"></div></div>
                </div>
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto small text-muted" style="font-size: 0.8rem;">{{ $filters.humanize(call.eventStart)}}</div>
                  <div class="col small text-muted text-end" style="font-size: 0.8rem; font-weight: bold;" v-if="call.duration">{{$filters.secondsToTime(call.duration)}}</div>
                  <div class="col small text-muted text-end" style="font-size: 0.8rem; font-weight: bold;" v-else>{{call.eventHungUpType}}</div>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="col-5 border-start ps-0">
          <div class="list-group list-group-item-light list-group-flush">
            <template v-for="user in $store.state.users" :key="user.id">

              <a class="list-group-item list-group-item-action" v-if="user.placetel && user.id != $auth.user().id" href="#" @click="dial(user.placetel.searchKey3)">
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto"><div style="width: 28px; height: 28px; background: transparent no-repeat top center; background-size: cover; border-width: 3px !important;" :style="{'background-image': 'url(' + $filters.image(user.profilImage, 'thumb') + ')'}"  :class="[$online.isOnline(user)? 'border-success' : 'border-light']" class="d-block rounded-circle bg-white shadow-sm"></div></div>
                  <div class="col">{{user.name}}</div>
                </div>
              </a>

            </template>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>

</style>