function generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

function getStatusName(status){
    switch(status){
        case 'draft':
            return 'status.draft';
        case 'waitingForRelease':
            return 'status.waitingForRelease';
        case 'released':
            return 'status.released';
        case 'rejected':
            return 'status.rejected';
        case 'created':
            return 'status.created';
        case 'sendToCustomer':
            return 'status.sendToCustomer';
        case 'deleted':
            return 'status.deleted';
        case 'complete':
            return 'status.complete';
        case 'replaced':
            return 'status.replaced';
        case 'paid':
            return 'status.paid';
        case 'reminder':
        case 'unpaid':
            return 'status.unpaid';
        case 'partlyPaid':
            return 'status.partlyPaid';
        case 'overpaid':
            return 'status.overpaid';
        case 'writtenOff':
            return 'status.writtenOff';
        case 'cancelled':
            return 'status.cancelled';
        case 'storno':
            return 'status.storno';
        case 'inCollection':
            return 'status.inCollection';
        default:
            return 'status.confirmed';
    }
}

function getOrderStatusName(status){
    switch(status){
        case 'draft':
            return 'systemstatus.draft';
        case 'waitingForRelease':
            return 'systemstatus.waitingForRelease';
        case 'create':
            return 'systemstatus.created';
        case 'sendToCustomer':
            return 'systemstatus.sendToCustomer';
        case 'deleted':
            return 'systemstatus.deleted';
        case 'complete':
            return 'systemstatus.complete';

        default:
            return 'systemstatus.confirmed';
    }
}


export {generateUUID, getStatusName}

