import { provide } from "vue";

export default {
    install: (app, options) => {
        function enableDebug() {
                alert('an');
        }

        //alert('Install');

        //app.config.globalProperties.debug = enableDebug;
        app.provide("debug", {enableDebug});
    },

};