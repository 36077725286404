<template>
    <div class="position-relative">
        <label v-if="label"
               :for="id" class="form-label" :class="[$attrs.required !== undefined ? 'required' : '', light ? 'text-white' : '']">{{ label }}</label>
        <select
               :id="id"
               :value="modelValue"
               class="form-select"
               :class="[hasError() ? 'is-invalid' : '']"
               @input="$emit('update:modelValue', $event.target.value)"
               v-bind="$attrs"
        >
            <option :value="''" disabled style="display:none;">{{$t('common.pleaseChoose')}}</option>
            <slot />
        </select>
        <div v-if="hasError()" class="invalid-feedback">
            <ul class="inputError">
                <li v-for="(error, index) in errors[this.name]" :key="index">{{error}}</li>
            </ul>
        </div>
    </div>
</template>

<script>

import {generateUUID} from "@/helper";
export default {
    name: "selectField",
    emits: ['update:modelValue'],
    props: {
        modelValue: {},
        id:{
            type: String,
            default(){
                return generateUUID();
            }
        },
        name: {
            type: String,
            required: true,
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
        label: {
          type: String,
        },
        light:{
          type: Boolean,
          default: false,
        }
    },
    computed: {
        value() {
            console.log(this.modelValue, typeof this.modelValue)
            if(typeof this.modelValue === undefined) {
                console.log("check checked undefined");
                return 3;
            }

            if(this.modelValue === null) {
                console.log("check checked null");
                return null;
            }

            return this.modelValue;
        }
    },
    methods: {
        hasError(){
            return this.errors[this.name] !== undefined;
        }
    }
}
</script>

<style scoped>

</style>