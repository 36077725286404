<script>
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
import InputField from "@/components/inputField.vue";
import moment from "moment";

export default {
  name: "modalAutoresponder",
  components: {
    InputField,
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
  },
  props: {
    shown: Boolean,
  },
  watch: {
    shown(value) {
      if(value === false) {
        this.reset();
      }
      else{
        this.$root.setModalHidden(false);
        this.getInfo();
      }
      this.showModal = value;
    },
    showModal(value) {
      this.$emit('update:shown', value);
    },
  },
  computed:{
    disabled(){
      if(!this.formData.toDate) return true;
      if(this.loading) return true;

      return false;
    },
    active(){
      return this.info && this.info.status && this.info.status.Status == 'true';
    }
  },
  data() {
    return {
      showModal: false,
      loading: false,
      gettingInfo: false,
      formData: {
        toDate: null,
        forward: null,
      },
      info: null,
    };
  },
  mounted() {
    this.showModal = this.shown;
    this.getInfo();
  },
  methods:{
    reset(){

    },
    getInfo(){
        let url = "autoResponder/info";
        this.gettingInfo = true;

        this.axios.get(url).then(res => {
            this.info = res.data;
            this.loading = false;
            this.gettingInfo = false;
        });
    },
    setAutoResponder(){
        let url = "autoResponder/set";
        this.loading = true;
        this.axios.post(url, this.formData).then(res => {
            this.info = res.data;
            this.$toast.success(this.$t('autoresponder.activate'));
            this.showModal = false;
        });
    },
    unsetAutoResponder(){
      let url = "autoResponder/unset";
      this.loading = true;

      this.axios.post(url).then(res => {
        this.info = res.data;
        this.$toast.success(this.$t('autoresponder.deactivate'));
        this.loading = false;
        this.showModal = false;
      });
    },
    minDate() {
      return moment().startOf('day').add(1, 'day').format('YYYY-MM-DD');
    },
  }
}
</script>

<template>
  <CModal :visible="showModal" size="md" alignment="top" @close="showModal=false" backdrop="static">
    <CModalHeader>
      <CModalTitle>{{ $t('autoresponder.autoresponder') }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div v-if="active">
        <div class="alert alert-info">{{ $t('autoresponder.isActive') }}</div>

        <div class="mt-3" v-html="info.status['Answer text']"></div>
      </div>
      <div v-else>
          <div class="mb-3">
            <input-field type="date" name="toDate" v-model="formData.toDate" :min="minDate()" :errors="errors" required :label="$t('autoresponder.autoresponder')"/>
          </div>
          <div class="mb-3">
              <label class="form-label required">{{$t('autoresponder.redirect')}}</label>
              <select class="form-select" v-model="formData.forward">
                <optgroup label="Optionen">
                  <option :value="null">{{$t('autoresponder.noRedirect')}}</option>
                </optgroup>
                <optgroup label="Sales-Manager">
                  <template v-for="user in $store.state.users" :key="user.id" >
                    <option :value="user.id" v-if="$auth.user().id != user.id">{{ user.name }}</option>
                  </template>
                </optgroup>
              </select>
          </div>
      </div>
    </CModalBody>
    <CModalFooter>
      <div class="col text-end">
        <button class="btn btn-outline-dark btn-sm me-3" @click="showModal=false">{{ $t('common.btnCancel') }}</button>
        <button class="btn btn-primary btn-sm" @click="setAutoResponder" v-if="!active" :disabled="disabled"> <i class="fa-solid fa-circle-notch fa-spin me-2" v-if="loading"></i>{{ $t('common.btnActivate') }}</button>
        <button class="btn btn-danger btn-sm" @click="unsetAutoResponder" v-if="active" :disabled="loading"><i class="fa-solid fa-circle-notch  fa-spin me-2" v-if="loading"></i>{{ $t('common.btnDeactivate') }}</button>
      </div>
    </CModalFooter>
  </CModal>
</template>

<style scoped>

</style>