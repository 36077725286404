<template>
    <div class="position-relative">
        <label v-if="label"
               :for="id" class="form-label" :class="[$attrs.required !== undefined ? 'required' : '']">{{ label }}</label>
        <div class="input-group" :class="[hasError() ? 'is-invalid' : '']">
            <slot />
            <input :type="type"
                   :id="id"

                   class="form-control text-end"

                   ref="inputRef"

                   v-bind="$attrs"
            >
            <slot name="behind" >
                <div class="input-group-text"><i class="fa-solid fa-euro-sign"></i></div>
            </slot>
        </div>
        <div v-if="hasError()" class="invalid-feedback">
            <ul class="inputError">
                <li v-for="(error, index) in errors[this.name]" :key="index">{{error}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import { watch } from 'vue' // Vue 2: import { watch } from '@vue/composition-api'
import { useCurrencyInput } from 'vue-currency-input'

import {generateUUID} from "@/helper";
export default {
    name: "inputCurrency",
    emits: ['update:modelValue'],
    props: {
        'modelValue': {
            type: Number,
            required: true
        },
        options: {
            type: Object,
            default(){
                return {
                    "currency": "EUR",
                    "currencyDisplay": "hidden",
                    "precision": 2,
                    "hideCurrencySymbolOnFocus": true,
                    "hideGroupingSeparatorOnFocus": true,
                    "hideNegligibleDecimalDigitsOnFocus": true,
                    "autoDecimalDigits": false,
                    "useGrouping": true,
                    "accountingSign": false
                };
            }
        },
        id:{
            type: String,
            default(){
                return generateUUID();
            }
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default() {
                return 'text';
            }
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
        label:{
            type: String,
        }
    },
    setup(props) {
        const { inputRef, setOptions, setValue } = useCurrencyInput(props.options)

        watch(
            () => props.modelValue, // Vue 2: props.value
            (value) => {
                setValue(value)
            }
        )

        watch(
            () => props.options,
            (options) => {
                setOptions(options)
            }
        )

        return { inputRef }
    },
    methods: {
        hasError(){
            return this.errors[this.name] !== undefined;
        }
    }
}
</script>

<style scoped>

</style>