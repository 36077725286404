<template>
    <CModal :visible="showModal" :size="modalSize" :fullscreen="fullscreen" alignment="top" @close="showModal=false" backdrop="static">
        <template v-if="viewMode=='companySelect'">
            <CModalHeader :closeButton="false">
                <CModalTitle>{{ $t('company.selectCompany') }}</CModalTitle>
            </CModalHeader>
            <CModalBody class="p-0">
                <crm-search :returnCompany="true" @company-selected="changeCompany"></crm-search>
            </CModalBody>
            <CModalFooter>
                <div class="col text-end">
                    <button class="btn btn-outline-dark me-3" @click="closeSelectionMode()">{{ $t('common.btnCancel') }}</button>
                </div>
            </CModalFooter>
        </template>
        <template v-else-if="viewMode=='company'">
            <CModalHeader>
                <CModalTitle v-if="action=='create'" v-html="$t('company.modalTitleCreate', {name:company.name})"></CModalTitle>
                <CModalTitle v-else v-html="$t('company.modalTitleEdit', {name:company.name})"></CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div class="row">
                    <div class="col mb-3">
                        <input-field
                            name="name"
                            :label="$t('company.companyName')"
                            :errors="errors"
                            required
                            type="text"
                            maxlength="255"
                            v-model="company.name"
                        />
                    </div>
                    <div class="col-4 mb-3" v-if="$auth.user().isShopAllowed">
                      <selectField name="Test" label="Sales Manager" v-model="company.salesManager_id" required>
                        <option :value="null">nicht zugewiesen</option>
                        <option v-for="user in $store.state.users" :key="user.id" :value="user.id">{{ user.name }}</option>
                      </selectField>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <select-field
                            name="country_id"
                            v-model="company.country_id"
                            :errors="errors"
                            :label="$t('address.country')"
                            required
                        >
                            <option v-for="country in $store.state.countries"  :key="country.id" :value="country.country_code_iso">{{ country.translated }}</option>
                        </select-field>
                    </div>
                    <div class="col mb-3" v-if="$store.state.branches">
                        <select-field
                            name="branch_id"
                            :label="$t('company.branch')"
                            :errors="errors"
                            required
                            v-model="company.branch_id"
                        >
                            <option v-for="branch in $store.state.branches" :key="branch.id" :value="branch.id">{{ branch.translated }}</option>
                        </select-field>
                    </div>
                    <div class="col mb-3" v-if="$store.state.branches">
                        <select-field
                            name="customerType_id"
                            v-model="company.customerType_id"
                            :label="$t('company.customerType')"
                            :errors="errors"
                            required
                        >
                            <option v-for="type in $store.state.customerTypes"  :key="type.id" :value="type.id">{{ type.translated }}</option>
                        </select-field>
                    </div>
                    <div class="col mb-3" v-if="company.customerType_id == 3">
                        <label for="companyReseller" class="form-label required">{{$t('common.reseller')}}</label>
                        <div class="input-group" :class="[errors['reseller_id'] !== undefined ? 'is-invalid' : '']">
                            <input id="addressMainAddress" :class="[errors['reseller_id'] !== undefined ? 'is-invalid' : '']" class="form-control readonly" :value="reseller" disabled readonly maxlength="255">
                            <button type="button" class="btn" :class="[company.customerType_id != 3 ? 'btn-outline-secondary' : 'btn-secondary']" :disabled="company.customerType_id != 3" @click="selectCompany()"><i class="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                        <div v-if="errors['reseller_id'] !== undefined" class="invalid-feedback">
                            <ul class="inputError">
                                <li v-for="(error, index) in errors['reseller_id']" :key="index">{{error}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <input-field
                            name="url"
                            :label="$t('company.url')"
                            :errors="errors"
                            type="url"
                            maxlength="255"
                            v-model="companyUrl"
                        >
                            <div class="input-group-text">https://</div>
                        </input-field>
                    </div>
                    <div class="col mb-3">
                        <input-field
                            name="email"
                            :label="$t('company.email')"
                            :errors="errors"
                            type="email"
                            maxlength="255"
                            v-model="company.email"
                        />
                    </div>
                </div>
                <div class="row" v-if="action=='create'">
                    <div class="col mb-3">
                        <div class="input-group">
                            <div class="input-group-text">
                                <input class="form-check-input mt-0" type="checkbox" id="createFlagging" v-model="createFlagging" aria-label="Checkbox for following text input">
                            </div>
                            <label class="input-group-text flex-grow-1" for="createFlagging"><i class="fa-solid fa-flag text-primary me-2"></i> {{ $t('company.createFlagging')}}</label>
                        </div>
                    </div>
                </div>
                <div v-if="possibleDublicates.length > 0">
                    <div class="card  mb-3 overflow-hidden">
                        <div class="card-header bg-secondary text-white">
                            {{$t('company.msg.possibleDuplicates')}}
                        </div>

                        <div class="card-body p-0 overflow-hidden">
                            <ol class="list-group list-group-flush">
                                <router-link :to="{name:'company', params: {id: hit.ref_id }}" @click="$root.setModalHidden(true)" active-class="active" class="list-group-item list-group-item-action" v-for="hit in possibleDublicates" :key="hit.id">
                                    <div class="ms-2 me-auto">
                                        <div class="fw-bold">{{hit.ref.name}}</div>
                                        <small><strong>usedSoftId:</strong> {{hit.ref.crm_id}}</small>
                                    </div>
                                </router-link>
                            </ol>
                        </div>
                    </div>

                    <div class="input-group">
                        <div class="input-group-text">
                            <input class="form-check-input mt-0" type="checkbox" id="duplicateCheck" v-model="company.duplicateCheck" aria-label="Checkbox for following text input">
                        </div>
                        <label class="input-group-text flex-grow-1" for="duplicateCheck">{{ $t('company.checkDuplicates')}}</label>
                    </div>

                </div>
            </CModalBody>
            <CModalFooter>
                <div class="col text-end">
                    <button class="btn btn-outline-dark me-3" @click="showModal=false" :disabled="btnDisabled()">{{ $t('common.btnCancel') }}</button>
                    <!--<button v-if="action=='create'" class="btn btn-secondary me-3" @click="btnCreate(false)" :disabled="btnDisabled()"><i class="fa-solid fa-circle-notch fa-spin me-2" v-if="saving"></i>{{ $t('company.btnCreate') }}</button>-->
                    <button v-if="action=='create'" class="btn btn-primary" @click="btnCreate(true)" :disabled="btnDisabled()"><i class="fa-solid fa-circle-notch fa-spin me-2" v-if="savingNext"></i>{{ $t('company.btnCreateAndNewAddress') }}</button>
                    <button v-if="action=='edit'" class="btn btn-primary" @click="btnSave()" :disabled="btnDisabled()"><i class="fa-solid fa-circle-notch fa-spin me-2" v-if="saving"></i>{{ $t('common.btnSaveChanges') }}</button>
                </div>
            </CModalFooter>
        </template>
        <template v-else-if="viewMode=='address'">
            <CModalHeader>
                <CModalTitle v-if="action=='create'" v-html="$t('address.modalTitleCreate')"></CModalTitle>
                <CModalTitle v-if="action=='edit'" v-html="$t('address.modalTitleEdit')"></CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div class="row">
                    <div class="col-8 mb-3">
                        <input-field name="street" max-length="150" v-model="address.street" :errors="errors" :label="$t('address.street')" required />
                    </div>
                    <div class="col-4 mb-3">
                        <input-field name="streetHouseNo" max-length="60" v-model="address.streetHouseNo" :errors="errors" :label="$t('address.streetHouseNo')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <select-field
                            name="country_id"
                            v-model="address.country_id"
                            :errors="errors"
                            :label="$t('address.country')"
                            required
                        >
                            <option :value="null" disabled style="display:none">Bitte wählen</option>
                            <option v-for="country in $store.state.countries"  :key="country.id" :value="country.country_code_iso">{{ country.translated }}</option>
                        </select-field>
                    </div>
                    <div class="col mb-3">
                        <input-field name="zipCode" max-length="30" v-model="address.zipCode" :errors="errors" :label="$t('address.zipcode')" required />
                    </div>
                    <div class="col mb-3">
                        <input-field name="city" max-length="60" v-model="address.city" :errors="errors" :label="$t('address.city')" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <phone-number-input name="phone" id="addressPhonenumber" :defaultCountry="modus.defaultCountry" v-model="address.phone" :errors="errors" :label="$t('address.phone')"></phone-number-input>
                    </div>
                    <div class="col mb-3">
                        <phone-number-input name="fax"  id="addressPhonenumber" :defaultCountry="modus.defaultCountry" icon="fax" v-model="address.fax" :errors="errors" :label="$t('address.fax')"></phone-number-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <input-field name="email" type="email" max-length="60" v-model="address.email" :errors="errors" :label="$t('address.email')" />
                    </div>
                    <div class="col mb-3">
                        <input-field name="vatId" max-length="255" v-model="address.vatId" :errors="errors" :label="$t('common.vatId')" />
                    </div>
                </div>

            </CModalBody>
            <CModalFooter>
                <div class="col text-end">
                    <button class="btn btn-outline-dark me-3" @click="showModal=false">{{ $t('common.btnCancel') }}</button>
                    <button v-if="action=='create'" class="btn btn-secondary me-3" @click="btnCreate(false)" :disabled="btnDisabled()"><i class="fa-solid fa-circle-notch fa-spin me-2" v-if="saving"></i> {{ $t('address.btnCreateAddress') }}</button>
                    <button v-if="action=='create'" class="btn btn-primary me-3" @click="btnCreate(true)" :disabled="btnDisabled()"><i class="fa-solid fa-circle-notch fa-spin me-2" v-if="savingNext"></i> {{ $t('address.btnCreateAddressContact') }}</button>
                    <button v-if="action=='edit'" class="btn btn-primary me-3" @click="btnSave()" :disabled="btnDisabled()"><i class="fa-solid fa-circle-notch fa-spin me-2" v-if="saving"></i>{{ $t('common.btnSaveChanges') }}</button>
                </div>
            </CModalFooter>
        </template>
        <template v-if="viewMode=='contact'">
            <CModalHeader>
                <CModalTitle v-if="action=='create'" v-html="$t('contact.modalTitleCreate', {name:contact.lastName})"></CModalTitle>
                <CModalTitle v-else v-html="$t('contact.modalTitleEdit', {name:contact.lastName})"></CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div class="row">
                    <div class="col-4 mb-3">
                        <input-field name="lastName" max-length="150" v-model="contact.lastName" :errors="errors" :label="$t('contact.lastName')" required />
                    </div>
                    <div class="col-4 mb-3">
                        <input-field name="firstName" max-length="150" v-model="contact.firstName" :errors="errors" :label="$t('contact.firstName')" />
                    </div>
                    <div class="col-4 mb-3">
                        <input-field name="middleName" max-length="150" v-model="contact.middleName" :errors="errors" :label="$t('contact.middleName')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <select-field
                            name="gender"
                            v-model="contact.gender"
                            :errors="errors"
                            :label="$t('contact.gender')"
                            required
                        >
                            <option :value="null" disabled style="display:none">Bitte wählen</option>
                            <option :value="'M'">{{$t('contact.genders.male')}}</option>
                            <option :value="'F'">{{$t('contact.genders.female')}}</option>
                            <option :value="'D'">{{$t('contact.genders.unknown')}}</option>
                        </select-field>
                    </div>
                    <div class="col mb-3">
                        <select-field
                            name="language"
                            v-model="contact.language"
                            :errors="errors"
                            :label="$t('common.language')"
                            required
                        >
                            <option :value="null" disabled style="display:none">{{ $t('common.pleaseChoose') }}</option>
                            <option :value="'DE'">{{ $t('languages.de') }}</option>
                            <option :value="'EN'">{{ $t('languages.en') }}</option>
                            <option :value="'FR'">{{ $t('languages.fr') }}</option>
                            <option :value="'IT'">{{ $t('languages.it') }}</option>
                            <option :value="'ES'">{{ $t('languages.es') }}</option>
                        </select-field>
                    </div>
                    <div class="col mb-3">
                        <input-field name="position" max-length="150" v-model="contact.position" :errors="errors" :label="$t('contact.position')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <input-field name="email" type="email" max-length="100" v-model="contact.email" :errors="errors" required :label="$t('contact.email')" />
                    </div>
                    <div class="col mb-3">
                  <select-field
                      name="language"
                      v-model="contact.isInvoiceUpload"
                      :errors="errors"
                      :label="'Rechnungszustellung'"
                      required
                  >
                    <option :value="0">via E-Mail</option>
                    <option :value="1">via Post</option>
                    <option :value="2">via Portal-Upload</option>
                  </select-field>
                    </div>
                    <div class="col mb-3">
                        <phone-number-input name="phone" v-model="contact.phone"  :defaultCountry="modus.defaultCountry" :errors="errors" :label="$t('contact.phone')" required></phone-number-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <phone-number-input name="fax" id="addressPhonenumber" icon="fax" :defaultCountry="modus.defaultCountry" v-model="contact.fax" :errors="errors" :label="$t('contact.fax')"></phone-number-input>
                    </div>
                    <div class="col mb-3">
                        <phone-number-input name="mobil" id="addressPhonenumber" :defaultCountry="modus.defaultCountry" v-model="contact.mobil" :errors="errors" :label="$t('contact.mobile')"></phone-number-input>
                    </div>
                </div>



            </CModalBody>
            <CModalFooter>
                <div class="col text-end">
                    <button class="btn btn-outline-dark  me-3" @click="showModal=false" :disabled="btnDisabled()">{{$t('common.btnCancel')}}</button>
                    <button v-if="action=='create'" class="btn btn-primary me-3" @click="btnCreate" :disabled="btnDisabled()"> <i class="fa-solid fa-circle-notch fa-spin me-2" v-if="saving"></i>{{ $t('contact.btnCreateContact') }}</button>
                    <button v-if="action=='edit'" class="btn btn-primary me-3" @click="btnSave()" :disabled="btnDisabled()"><i class="fa-solid fa-circle-notch fa-spin me-2" v-if="saving"></i>{{ $t('common.btnSaveChanges') }}</button>
                </div>
            </CModalFooter>
        </template>
    </CModal>
</template>

<script>
import {
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from "@coreui/bootstrap-vue";
import OpenOffers from "@/pages/home/openOffers.vue";
import PhoneNumberInput from "@/components/phoneNumberInput.vue";

import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import InputField from "@/components/inputField.vue";
import SelectField from "@/components/selectField.vue";
import eventBus from "@/eventBus";
import CrmSearch from "@/pages/crm/crmSearch.vue";


export default {
    name: "modalCompany.vue",
    components: {
        CrmSearch,
        SelectField,
        InputField,
        PhoneNumberInput,
        OpenOffers,
        CModal, CModalBody,
        CModalFooter,
        CModalHeader,
        CModalTitle,
        Field,
        ErrorMessage,
        Form
    },
    emits: ['update:company', 'update:address', 'update:contact', 'create:company', 'create:address','create:contact', 'update:shown'],
    props: {
        shown: Boolean,
        modus: {
            type: Object,
            default(){
                return {
                    modus: 'company',
                };
            },
        }
    },
    methods: {
        reset(){
            this.errors = {};
            this.possibleDublicates = [];
            this.saving = this.savingNext = false;

            this.company_id = null;
            this.address_id = null;

            this.company = {...this.companyDefault};
            this.address = {...this.addressDefault};
            this.contact = {...this.contactDefault};
        },
        changeCompany(row){
            this.company.reseller = row;
            this.company.reseller_id = row.id;
            this.closeSelectionMode();
        },
        selectCompany(){
            this.viewMode = 'companySelect';
            this.fullscreen = true;
        },
        closeSelectionMode(){
            this.viewMode = 'company';
            this.fullscreen = false;
        },
        btnDisabled(){
            if(this.saving) return true;
            if(this.savingNext) return true;

            return false;
        },
        load(){
            switch(this.viewMode){
                case "company":
                    this.axios.get('crm/companies/'+this.modus.company_id).then(res => {
                        this.company = res.data;
                    });
                    break;
                case "address":
                    this.axios.get('crm/addresses/'+this.modus.address_id).then(res => {
                        this.address = res.data;
                    });
                    break;
                case "contact":
                    this.axios.get('crm/contacts/'+this.modus.contact_id).then(res => {
                        this.contact = res.data;
                    });
                    break;
            }
        },
        btnSave(){
            this.saving = this.savingNext = true;

            this.errors = {};

            switch(this.viewMode) {
                case 'company':
                    this.axios.put('crm/companies/'+this.modus.company_id, this.company).then(res => {
                        if(res.data.success === true){
                            this.$toast.success(this.$t('company.msg.updated', {companyname: this.company.name}));
                            eventBus.$emit('company', {action: "update",  data: res.data.data});
                            this.showModal = false;
                            this.reset();
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                            this.$toast.error(this.$t('common.inputError'));
                        }
                        else{
                            this.$toast.error(this.$t('common.error'));
                        }
                        this.saving = this.savingNext = false;
                    });
                    break;
                case 'address':
                    this.axios.put('crm/addresses/'+this.modus.address_id, this.address).then(res => {
                        if(res.data.success === true){
                            this.$toast.success(this.$t('address.msg.updated'));
                            eventBus.$emit('address', {action: "update",  data: res.data.data});
                            this.showModal = false;
                            this.reset();
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                            this.$toast.error(this.$t('common.inputError'));
                        }
                        else{
                            this.$toast.error(this.$t('common.error'));
                        }
                        this.saving = this.savingNext = false;
                    });
                    break;
                case 'contact':
                    this.axios.put('crm/contacts/'+this.modus.contact_id, this.contact).then(res => {
                        if(res.data.success === true){
                            this.$toast.success(this.$t('contact.msg.updated', {name: this.contact.lastName}));
                            eventBus.$emit('contact', {action: "update",  data: res.data.data});
                            this.showModal = false;
                            this.reset();
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                            this.$toast.error(this.$t('common.inputError'));
                        }
                        else{
                            this.$toast.error(this.$t('common.error'));
                        }
                        this.saving = this.savingNext = false;
                    });
                    break;
            }
        },
        btnCreate(next){
            if(next == true){
                this.savingNext = true;
            }
            else{
                this.saving = true;
            }

            this.hits = [];
            this.errors = {};

            switch(this.viewMode){
                case 'company':
                    this.company.createFlagging = this.createFlagging;

                    this.axios.post('crm/companies', this.company).then(res => {

                        if(res.data.success === true){
                            this.$toast.success(this.$t('company.msg.created', {companyname: this.company.name}));

                            this.company_id = res.data.company.id;
                            this.$router.push({
                                name: 'company',
                                params: {
                                    id: this.company_id
                                }
                            });

                            if(next){
                                this.viewMode = "address";
                            }
                            else{
                                this.showModal = false;
                                this.reset();
                            }
                        }
                        else{
                            switch(res.data.errorCode){
                                case 1000:
                                    this.$toast.info(this.$t('company.msg.possibleDuplicates'));
                                    this.possibleDublicates = res.data.hits;
                                    break;

                            }
                        }

                        this.saving = this.savingNext = false;
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                            this.$toast.error(this.$t('common.inputError'));
                        }
                        else{
                            this.$toast.error(this.$t('common.error'));
                        }
                        this.saving = this.savingNext = false;
                    });
                    break;
                case 'address':
                    if(this.company_id){
                        this.address.company_id = this.company_id;
                    }
                    else{
                        this.address.company_id = this.modus.company_id;
                    }

                    this.axios.post('crm/addresses', this.address).then(res => {
                        if(res.data.success === true){
                            this.$toast.success(this.$t('address.msg.created'));

                            this.address_id = res.data.address.id;
                            this.$router.push({
                                name: 'address',
                                params: {
                                    addressId: this.address_id
                                }
                            });

                            if(next){
                                this.viewMode = "contact";
                            }
                            else{
                                this.showModal = false;
                                this.reset();
                            }
                        }

                        this.saving = this.savingNext = false;
                    })
                    .catch(error => {
                        console.log('Fehler:', error);
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                            this.$toast.error(this.$t('common.inputError'));
                        }
                        else{
                            this.$toast.error(this.$t('common.error'));
                        }
                        this.saving = this.savingNext = false;
                    });
                    break;
                case 'contact':
                    if(this.address_id){
                        this.contact.address_id = this.address_id;
                    }
                    else{
                        this.contact.address_id = this.modus.address_id;
                    }

                    this.axios.post('crm/contacts', this.contact).then(res => {
                        if(res.data.success === true){
                            this.$toast.success(this.$t('contact.msg.created', {name: this.contact.name}));

                            this.contact_id = res.data.contact.id;
                            this.$router.push({
                                name: 'contact',
                                params: {
                                    contactId: this.contact_id
                                }
                            });

                            this.showModal = false;
                            this.reset();
                        }

                        this.saving = this.savingNext = false;
                    })
                        .catch(error => {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors || {};
                                this.$toast.error(this.$t('common.inputError'));
                            }
                            else{
                                this.$toast.error(this.$t('common.error'));
                            }
                            this.saving = this.savingNext = false;
                        });
                    break;
            }
        }
    },
    computed:{
        reseller(){
            if(this.company.reseller) return this.company.reseller.name;

            return '';
        },
        companyUrl:{
            get(){
                if(this.company.url == null) return null;

                return this.company.url.replace('https://', '');
            },
            set(value){
                if(value == null || value.trim() == '') {
                    this.company.url = null;
                    return
                }
                this.company.url = 'https://' + value;
            }
        }
    },
    watch: {
        modus(){
            if(this.modus.action != 'create') return;
            if(this.modus.modus == 'company') return;

            console.log(this.modus);
            //alert('weiter');
        },
        shown(value) {
            this.showModal = value;
            this.viewMode = this.modus.modus;
            this.action = this.modus.action;

            if(!value){
                this.reset();
            }
            else{
                if(this.action == 'edit'){
                    this.load();
                }
            }

            if(this.modus.defaultCountry != undefined && this.modus.modus == "address"){
              this.address.country_id = this.modus.defaultCountry;
            }
            //console.log('Modus empty?', this.modus);
        },
        showModal(value) {
            this.$root.setModalHidden(false);
            this.$emit('update:shown', value);
        }
    },
    data() {
        return {
            errors: {},
            viewMode: 'company',
            action: 'create',
            showModal: false,
            modalSize: 'xl',
            fullscreen: false,
            saving: false,
            savingNext: false,
            possibleDublicates: [],
            company_id: null,
            address_id: null,
            createFlagging: false,
            company: {},
            companyDefault: {
                name: '',
                branch_id: null,
                country_id: null,
                customerType_id: null,
                reseller_id: null,
                url: null,
                email: null,
                duplicateCheck: false,
            },
            schemaCompany: yup.object({
                companyName: yup.string().required().min(8).label('Firmenname'),
                companyBranch: yup.string().required().label('Branche'),
                companyEMail: yup.string().email().label('E-Mail-Adresse'),
                companyUrl: yup.string().url().label('Url/Webseite'),
            }),
            address:{},
            addressDefault: {
                street: null,
                streetHouseNo: null,
                zipCode: null,
                city: null,
                country_id: null,
                phone: null,
                fax: null,
                email: null,
                vatId: null,
            },
            contact: {},
            contactDefault: {
                gender: null,
                firstName: null,
                middleName: null,
                lastName: null,
                language: null,
                email: null,
                position: null,
                phone: null,
                fax: null,
                mobil: null,
                isInvoiceUpload: 0,
            }
        };
    },
    mounted() {
        this.reset();
        this.showModal = this.shown;
    }
}
</script>

<style scoped>

</style>