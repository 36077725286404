<template>
  <div class="Tab mt-1" v-if="$auth.check()">
    <ul class="tabbar"  v-dragscroll.x style="width: 100%; max-width: 100%; overflow: hidden; white-space: nowrap;">
      <router-link v-for="tab in tabs" :key="tab"
          :to="{name:tab.name, params: tab.params}"
          custom
          v-slot="{ href, route, navigate, isActive, isExactActive }"
      >
        <li :class="{active: isActive, exactActive: isExactActive}" :title="tab.title">
          <a :href="href" @click="navigate" ><i v-if="tab.icon" class="fa-fw text-primary me-1"  :class="tab.icon"></i>{{ tab.title }}</a>
          <button v-if="route.meta.closeable == undefined || route.meta.closeable" class="btn btn-close" aria-label="Close" @click="closeTab(route)"></button>
        </li>
      </router-link>

      <!--<router-link
          :to="{name:'dashboard'}"
          custom
          v-slot="{ href, route, navigate, isActive, isExactActive }"
      >
        <li :class="{active: isActive, exactActive: isExactActive}">
            <a :href="href" @click="navigate">{{ route.name }} Dropdown Beispiel</a>
            <div class="dropdown">
            <CDropdown color="" data-bs-reference="parent">
              <CDropdownToggle></CDropdownToggle>
              <CDropdownMenu >
                <CDropdownItem href="#">Action</CDropdownItem>
                <CDropdownItem href="#">Another action</CDropdownItem>
                <CDropdownItem href="#">Something else here</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            </div>
        </li>
      </router-link>-->
    </ul>
  </div>
</template>

<script>
//import {CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem} from '@coreui/bootstrap-vue';

export default {
  name: "Tab",
  components:{
    //CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem
  },
  computed:{
      tabs(){
        return this.$store.state.tabbar.tabs;
      }
  },
  watch: {
      '$route' (to, from){
        this.$store.commit('tabbar/addRoute', to);
      }
  },
  mounted(){
    //console.log(this.$store.state.tabbar.tabs);
  },
  methods:{
    closeTab(route){
      this.$store.commit('tabbar/delRoute', route);
    }
  },
  data(){
    return{
      viewKey: 0,
    }
  }
}
</script>

<style scoped>

</style>