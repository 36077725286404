<template>
    <CModal :visible="showModal" size="lg" alignment="top" @close="showModal=false" backdrop="static">
        <CModalHeader>
            <CModalTitle v-html="$t('flagging.modalTitle', {companyname:company.name})"></CModalTitle>
        </CModalHeader>
        <CModalBody>
            <div class="mb-3">
                <select-field name="reason_id" v-model="formData.reason_id" required :label="$t('flagging.flaggingReason')">
                    <option v-for="reason in reasons" :value="reason.id" :key="reason.id">{{reason.name}}</option>
                </select-field>
            </div>
            <div class="mb-3">
                <text-area-field name="text" v-model="formData.text" :label="$t('common.notice')"></text-area-field>
            </div>
        </CModalBody>
        <CModalFooter>
            <div class="col text-end">
                <button class="btn btn-outline-dark btn-sm me-3" @click="showModal=false">{{ $t('common.btnCancel') }}</button>
                <button class="btn btn-primary btn-sm" :disabled="isDisabled()" @click="sendContract()">{{  $t('flagging.btnApply') }}</button>
            </div>
        </CModalFooter>
    </CModal>
</template>

<script>
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
import {isDisabled} from "bootstrap/js/src/util";
import TextAreaField from "@/components/textAreaField.vue";
import SelectField from "@/components/selectField.vue";

export default {
    name: "modalFlagging",
    components: {
        SelectField,
        TextAreaField,
        CModal, CModalBody,
        CModalFooter,
        CModalHeader,
        CModalTitle,
    },
    props: {
        shown: Boolean,
        company: Object,
    },
    watch: {
        shown(value) {
            if(value === false) this.reset();
            this.showModal = value;
        },
        showModal(value) {
            this.$root.setModalHidden(false);
            this.$emit('update:shown', value);
        }
    },
    computed: {
      reasons() {
        return [
            {id: 1, name: this.$t('flagging.reasons.firstContact')},
            {id: 2, name: this.$t('flagging.reasons.customerRequest')},
            {id: 3, name: this.$t('flagging.reasons.takeover')},
          ];
      }
    },
    data() {
        return {
            showModal: false,
            formData: {
                reason_id: null,
                text: "",
            }
        };
    },
    mounted() {
        this.showModal = this.shown;
    },
    methods:{
        reset(){
            this.formData.reason_id = null;
            this.formData.text = '';
        },
        isDisabled(){
            if(!this.formData.reason_id) return true;
            if(!this.company) return true;

            return false;
        },
        sendContract(){
            let data ={
                reason_id: this.formData.reason_id,
                text: this.formData.text,
                company_id: this.company.id,
            };

            this.axios.post('crm/flagging', data).then(res => {
                if(res.data.success){
                    this.$toast.success(this.$t('flagging.requestApplied'));
                    this.showModal = false;
                }
            });
        }
    }
}
</script>

<style scoped>

</style>