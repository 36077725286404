<script>
export default {
  name: "menuNotifications"
}
</script>

<template>
  <div class="dropdown border-start">
    <a href="#" class="d-flex align-items-center justify-content-center px-2 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa-solid fa-triangle-exclamation"><span v-if="$store.state.notifications.counts.count > 0" class="position-absolute bottom-100 right-0 badge rounded-pill bg-danger">{{ $store.state.notifications.counts.count }}</span></i>

    </a>
    <div class="dropdown-menu dropdown-menu-end text-small shadow p-0 overflow-hidden" style="min-width: 15vw;">
      <p class="p-3 m-0" v-if="$store.state.notifications.counts.count == 0">{{ $t('notifications.noData') }}</p>
      <ul class="list-group list-group-flush m-0">
        <template v-for="notif in $store.state.notifications.notifications.flagging" :key="notif.id">
          <router-link class="list-group-item list-group-item-action" :to="{name: 'flagging'}">

            <div class="d-flex w-100 justify-content-between">
              <div style="font-weight: bold;"><i class="fa-solid fa-flag text-primary"></i> Flagging Antrag</div>
              <small>{{ $filters.date(notif.created_at) }}</small>
            </div>
            <small class="text-muted" style="text-overflow: ellipsis; display: block; white-space: nowrap;"><b>Von:</b> {{notif.user.name}}</small>
            <small class="text-muted" style="text-overflow: ellipsis; display: block; white-space: nowrap;"><b>Firma:</b> {{notif.company.name}}</small>
          </router-link>
        </template>
      </ul>
      <ul class="list-group list-group-flush m-0">
        <template v-for="notif in $store.state.notifications.notifications.releases" :key="notif.id">
          <router-link class="list-group-item list-group-item-action" :to="{name: 'offer', params: {id: notif.id}}">

            <div class="d-flex w-100 justify-content-between">
              <div style="font-weight: bold;"><i class="fa-solid fa-tower-control text-secondary"></i> Angebotsfreigabe</div>
              <small>{{ $filters.date(notif.requestDate) }}</small>
            </div>
            <small class="text-muted" style="text-overflow: ellipsis; display: block; white-space: nowrap;"><b>Von:</b> {{notif.requestuser.name}}</small>
            <small class="text-muted" style="text-overflow: ellipsis; display: block; white-space: nowrap;"><b>Firma:</b> {{notif.company.name}}</small>
          </router-link>
        </template>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>