<template>
    <div class="container-fluid h-100 d-flex flex-column">
        <div class="row flex-grow-1 flex-shrink-1 mb-3" style="min-height: 0; flex-basis: 10vh;">
            <div class="col h-100" style="height: 100%; overflow: auto;">
                <div class="card h-100" style="height: 100%; overflow: auto;">
                    <div class="card-header">{{$t('address.addresses')}}</div>
                    <perfect-scrollbar class="h-100">
                        <div class="card-body p-0">
                            <data-view
                                    :fields="addressFields"
                                    :data="company.addresses"
                                    @rowClick="gotoAddress"
                            >
                                <template #cell(status)="row">
                                    <i class="fa-solid fa-circle-xmark text-danger" v-if="row.row.isDeactivated"></i>
                                    <i class="fa-solid fa-circle-check text-success" v-else></i>

                                </template>
                                <template #cell(street)="row">
                                    {{row.value}} {{row.row.streetHouseNo}}
                                </template>
                                <template #cell(city)="row">
                                    {{row.row.zipCode}} {{row.value}}
                                </template>
                            </data-view>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
        <div class="row flex-grow-1 flex-shrink-1 mb-3" style="min-height: 0; flex-basis: 10vh;">
            <div class="col" style="height: 100%; overflow: auto;">
                <div class="card h-100 " style="height: 100%; overflow: auto;">
                    <div class="card-header">{{ $t('contact.contacts') }}</div>
                    <perfect-scrollbar class="h-100">
                        <div class="card-body p-0">
                            <data-view
                                    :fields="contactFields"
                                    :data="company.contacts"
                                    @rowClick="gotoContact"
                            >
                                <template #cell(status)="row">
                                    <i class="fa-solid fa-circle-xmark text-danger" v-if="row.row.isDeactivated"></i>
                                    <i class="fa-solid fa-circle-check text-success" v-else></i>
                                </template>
                              <template #cell(#)="row">
                                <div style="white-space: nowrap">
                                  <i v-if="company.defaultDelivery_id == row.row.id" class="fa-solid fa-truck text-success mx-1" :title="$t('contact.defaultDeliveryAddress')"></i>
                                  <i v-if="company.defaultInvoice_id == row.row.id" class="fa-solid fa-file-invoice text-success mx-1" :title="$t('contact.defaultInvoiceAddress')"></i>
                                </div>
                              </template>
                            </data-view>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
        <div class="row flex-grow-1 flex-shrink-1 mb-3" style="min-height: 0; flex-basis: 10vh;" v-if="company.shop_users.length > 0">
            <div class="col" style="height: 100%; overflow: auto;">
                <div class="card h-100 " style="height: 100%; overflow: auto;">
                    <div class="card-header">{{ $t('common.shopUser') }}</div>
                    <perfect-scrollbar class="h-100">
                        <div class="card-body p-0">
                            <data-view
                                    :fields="shopUserFields"
                                    :data="company.shop_users"
                                    @rowClick="gotoShopUser"
                            >
                                <template #cell(status)="row">
                                    <i class="fa-solid fa-circle-xmark text-danger" v-if="row.row.disabled"></i>
                                    <i class="fa-solid fa-circle-check text-success" v-else></i>

                                </template>
                              <template #cell(street)="row">
                                  {{row.value}} {{row.row.streetnr}}
                              </template>
                            </data-view>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
      <div class="row flex-grow-1 flex-shrink-1" style="min-height: 0; flex-basis: 20vh;" v-if="company.customerType_id == 2">
        <div class="col" style="height: 100%; overflow: auto;">
            <widget-customer :company="company.id"></widget-customer>
        </div>
      </div>
    </div>
</template>

<script>
import dataView from "@/components/dataView.vue";
import WidgetCustomer from "@/pages/widgets/widgetCustomer.vue";

export default {
    name: "CompanyTabOverview",
    components: {WidgetCustomer, dataView},
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    computed:{
        contactFields(){
            return [
              {key: 'status', label: this.$t('common.status'), thClass: "col-auto text-center", tdClass: ' col-auto text-center', sortable: false },
              {key: 'usedSoftId', label: 'UID', thClass: "col-4", tdClass: ' col-4', sortable: false },
              {key: 'firstName', label: this.$t('contact.firstName'), thClass: "col-4", tdClass: ' col-4', sortable: false },
              {key: 'lastName', label: this.$t('contact.lastName'), thClass: "col-4", tdClass: ' col-4', sortable: false },
              {key: '#', label: '#', thClass: "col-auto text-center", tdClass: 'col-auto text-center', sortable: false }
            ];
        },
        addressFields(){
          return [
            {key: 'status' , label: this.$t('common.status'), thClass: "col-auto text-center", tdClass: ' col-auto text-center', sortable: false },
            {key: 'crm_id' , label: 'UID2', thClass: "col-3", tdClass: ' col-3', sortable: false },
            {key: 'street' , label: this.$t('address.street'), thClass: "col-3", tdClass: ' col-3', sortable: false },
            {key: 'city' , label: this.$t('address.city'), thClass: "col-3", tdClass: ' col-3', sortable: false },
            {key: 'country_id' , label: this.$t('address.country'), thClass: "col-3", tdClass: ' col-3', sortable: false },
          ];
        },
        shopUserFields(){
          return [
            {key: 'status' , label: this.$t('common.status'), thClass: "col-auto text-center", tdClass: ' col-auto text-center', sortable: false },
            {key: 'shop_custnr' , label: 'ShopId', thClass: "col-1", tdClass: ' col-1', sortable: false },
            {key: 'company', label: this.$t('company.company'), thClass: "col-3", tdClass: ' col-3', sortable: false },
            {key: 'fname', label: this.$t('contact.firstName'), thClass: "col-2", tdClass: ' col-2', sortable: false },
            {key: 'lname', label: this.$t('contact.lastName'), thClass: "col-2", tdClass: ' col-2', sortable: false },
            {key: 'street' , label: this.$t('address.street'), thClass: "col-3", tdClass: ' col-3', sortable: false },
            {key: 'city' , label: this.$t('address.city'), thClass: "col-2", tdClass: ' col-2', sortable: false },
            {key: 'country' , label: this.$t('address.country'), thClass: "col-1", tdClass: ' col-1', sortable: false },
          ];
        },
    },
    data(){
        return {
            addresses: {
                //fields:
            },
            contacts: {
                //fields:
            }
        }
    },
    methods: {
        gotoAddress(raw) {
            this.$router.push({name: 'address', params: {addressId: raw.id}});
        },
        gotoContact(raw) {
            this.$router.push({name: 'contact', params: {contactId: raw.id}});
        },
        gotoShopUser(raw) {
          this.$router.push({name: 'crmShopUser', params: {userId: raw.id}});
          //this.$router.push({name: 'crmShopUsers'});
        },
    }
}
</script>

<style scoped>

</style>