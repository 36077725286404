<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "toastOfferDecision",
  emits: ['goForIt'],
  props: {
    data: Object
  }
})
</script>

<template>
    <div>
        <template v-if="data.status == 'released'">{{ $t('common.released', { company: data.company })}} </template>
        <template v-else>{{ $t('common.rejected', { company: data.company }) }}</template>

      <div class="mt-1 d-grid g-2">
        <button class="btn btn-primary btn-xs" @click="this.$emit('goForIt')">{{$t('common.goToOffer')}}</button>
      </div>
    </div>
</template>

<style scoped>

</style>