<template>
    <div class="position-relative">
        <label v-if="label"
               :for="id" class="form-label" :class="[$attrs.required !== undefined ? 'required' : '']">{{ label }}</label>
        <div class="input-group" :class="[hasError() ? 'is-invalid' : '']">
            <slot />
            <input :type="type"
                   :id="id"
                   :value="modelValue"
                   class="form-control"
                   :class="[$attrs.readonly !== undefined ? 'disabled' : '', hasError() ? 'is-invalid' : '']"

                   @input="setValue($event.target.value)"
                   v-bind="$attrs"


            >
            <slot name="behind" />
        </div>
        <div v-if="hasError()" class="invalid-feedback">
            <ul class="inputError">
              <template v-for="(error, index) in errors[this.name]" :key="index">
                <li v-if="!Array.isArray(error)">{{error}}</li>
                <template v-else>
                  <li v-for="(e, i) in error" :key="i">{{e}}</li>
                </template>
              </template>
            </ul>
        </div>
    </div>
</template>

<script>

import {generateUUID} from "@/helper";
export default {
    name: "inputField",
    emits: ['update:modelValue'],
    props: {
        'modelValue': {
            type: String,
            required: true
        },
        id:{
            type: String,
            default(){
                return generateUUID();
            }
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default() {
                return 'text';
            }
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
        label:{
            type: String,
        },
        withDelay:{
            type: Boolean,
            default(){
              return false;
            }
        },
        delay: {
          type: Number,
          default(){
            return 1000;
          }
        }
    },
    data(){
        return{
            inputDelay: null,
        }
    },
    methods: {
        setValue(value){
          if(this.withDelay && this.inputDelay){
              clearTimeout(this.inputDelay);
              this.inputDelay = null;
          }

          if(!this.withDelay){
            this.$emit('update:modelValue', value);
            return;
          }

          this.inputDelay = setTimeout(() => {
            this.$emit('update:modelValue', value);
            this.inputDelay = null;
          }, this.delay);

        },
        hasError(){
            return this.errors[this.name] !== undefined;
        }
    }
}
</script>

<style scoped>

</style>