<script>
import {defineComponent} from 'vue'
import {getStatusName} from "@/helper";
import dataView from "@/components/dataView.vue";

export default defineComponent({
  name: "widgetCustomer",
  components: {dataView},
  props: {
    company: Number,
  },
  data(){
    return {
      delay: null,
      formData: {
      },
      loading: false,
      searchDefault:{
        term: '',
        type: null,
        status: 'unpaid',
        salesManager: null,
        dateFrom: null,
        dateTo: null,
        sortBy: 'invoiceDate',
        sortDesc: true,
        page: 1,
      },
      dataView: { }
    }
  },
  beforeMount() {
    this.formData = {...this.searchDefault};
  },
  mounted(){
    this.load();
  },
  watch: {
    'formData.sortDesc'(){
      this.load();
    },
    'formData.sortBy'(){
      this.load();
    },
    '$i18n.locale'(locale){
      this.load();
    }
  },
  computed:{
    data(){
      if(this.dataView.data === undefined){
        return [];
      }

      let data = this.dataView.data;

      for(let item of data){
        if(item.paymentState == 'overpaid'){
          item._rowVariant = "info";
        }
        if(item.isDunningDisabled){
          item._rowVariant = "info";
        }
        if(item.paymentDaysLeft > 0 && (item.paymentState == 'unpaid' || item.paymentState == 'partlyPaid')){
          item._rowVariant = "warning";
        }
      }

      return data;
    },
    fields(){
        return [
          {key: 'status', label: this.$t('common.status'), thClass: "col-auto text-center", tdClass: ' col-auto text-center', sortable: false },
          {key: 'crm_id', label: 'UID1', thClass: "col-1", tdClass: 'text-end col-1', sortable: true },
          {key: 'name', label: this.$t('company.company'), thClass: "col-6", tdClass: 'col-6', sortable: true },
          {key: 'address.zipCode', label: this.$t('address.zipcode'), thClass: "col-auto", tdClass: 'col-auto', sortable: true },
          {key: 'address.city', label: this.$t('address.city'), thClass: "col-2", tdClass: 'col-2', sortable: true },
          {key: 'country_id', label: this.$t('address.country'), thClass: "col-auto", tdClass: 'col-auto', sortable: true },
          {key: 'branch.branch_name', label: this.$t('company.branch'), thClass: "col-2", tdClass: ' col-2', sortable: true },
        ];
      }
  },
  methods:{
    getStatus(value){
      return this.$t(getStatusName(value));
    },
    reset(){
      this.formData = {...this.searchDefault};
      this.load();
    },
    load(){
      let url = 'crm/companies/' + this.company.toString() + "/customers";

      if(this.delay){
        clearTimeout(this.delay);
        this.delay = null;
      }


      this.delay = setTimeout(() => {
        this.loading = true;
        this.axios.post(url, this.formData).then(res => {
          this.dataView = res.data;

          if(this.dataView.current_page > this.dataView.last_page){
            this.formData.page = 1;
            this.load();
          }

          this.loading = false;
        }).catch(error => {
          this.loading = false;
          this.$toast.error(this.$t('common.error'));
        });
      }, 200);
    },
    setPage(value){
      this.formData.page = value;
      this.load();
    },
    firstPage(){
      this.formData.page = 1;
      this.load();
    },
    lastPage(){
      this.formData.page = this.dataView.last_page;
      this.load();
    },
    prevPage(){
      if(this.formData.page <= 1) return 0;

      this.formData.page -= 1;
      this.load();
    },
    nextPage(){
      if(this.dataView.current_page >= this.dataView.last_page) return 0;

      this.formData.page += 1;
      this.load();
    },
    goto(raw) {
      this.$router.push({name: 'company', params: {id: raw.id}});
    },
  }
})
</script>

<template>
  <div class="card h-100">
    <div class="card-header">Kunden vom Händler</div>
    <perfect-scrollbar class="h-100" :disable="true">
      <div class="card-body p-0">
        <data-view
            :loading="loading"
            :fields="fields"
            :data="data"
            v-model:sort-by="formData.sortBy"
            v-model:sort-desc="formData.sortDesc"
            @rowClick="goto"
        >
          <template #cell(status)="row">
            <i class="fa-solid fa-octagon-exclamation text-danger" title="Liefersperre" v-if="row.row.isDeliveryBlocked"></i>
            <i class="fa-solid fa-circle-xmark text-danger" v-else-if="row.row.isDeactivated"></i>
            <i class="fa-solid fa-circle-check text-success" v-else></i>
          </template>
          <template #cell(customertype-name)="row">
            <template v-if="row.row.customertype">
              {{row.row.customertype.translated}}
            </template>
          </template>
          <template #cell(country-name)="row">
            <template v-if="row.row.country">
              {{row.row.country.translated}}
            </template>
          </template>
          <template #cell(branch-branch_name)="row">
            <template v-if="row.row.branch">
              {{row.row.branch.translated}}
            </template>
          </template>

        </data-view>
      </div>
    </perfect-scrollbar>
    <div class="card-footer py-1">
      <div class="row align-items-center">
        <div class="col-auto" v-if="dataView.current_page">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-primary btn-sm" :disabled="dataView.current_page == 1" @click="firstPage()"><i class="fa-solid fa-arrow-left-from-line"></i></button>
            <button class="btn btn-primary btn-sm" :disabled="dataView.current_page == 1" @click="prevPage()"><i class="fa-solid fa-arrow-left" ></i></button>
            <select class="form-select form-select-sm"  v-model="formData.page" @change="setPage(formData.page)">
              <option v-for="n in dataView.last_page" :value="n" :key="n">{{ n }}</option>
            </select>
            <button class="btn btn-primary btn-sm" :disabled="dataView.current_page >= dataView.last_page" @click="nextPage()"><i class="fa-solid fa-arrow-right"></i></button>
            <button class="btn btn-primary btn-sm" :disabled="dataView.current_page >= dataView.last_page" @click="lastPage()"><i class="fa-solid fa-arrow-right-from-line"></i></button>
          </div>
        </div>
        <div class="col text-center" v-if="dataView.total">{{dataView.from}}-{{dataView.to}} / {{dataView.total}}</div>

      </div>
    </div>
  </div>
</template>

<style scoped>

</style>