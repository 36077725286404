<template>
    <div class="position-relative">
        <label v-if="label"
               :for="id" class="form-label" :class="[$attrs.required !== undefined ? 'required' : '']">{{ label }}</label>
        <div class="input-group" :class="[hasError() ? 'is-invalid' : '']">
            <slot />
            <textarea
                   :id="id"
                   :value="modelValue"
                   class="form-control"
                   :class="[hasError() ? 'is-invalid' : '']"
                   @input="$emit('update:modelValue', $event.target.value)"
                   v-bind="$attrs"
                   style="resize: none;" :style="{'min-height': minHeight}"
            ><slot name="value" /></textarea>
        </div>
        <div v-if="hasError()" class="invalid-feedback">
            <ul class="inputError">
                <li v-for="(error, index) in errors[this.name]" :key="index">{{error}}</li>
            </ul>
        </div>
    </div>
</template>

<script>

import {generateUUID} from "@/helper";
export default {
    name: "textAreaField",
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
            required: true
        },
        id:{
            type: String,
            default(){
                return generateUUID();
            }
        },
        name: {
            type: String,
            required: true,
        },
        'minHeight': {
          type: String,
          default() {
            return "35vh";
          }
        },
        errors: {
            type: Object,
          default() {
            return {};
          }
        },
        label:{
            type: String,
        }
    },
    methods: {
        hasError(){
            return this.errors[this.name] !== undefined;
        }
    }
}
</script>

<style scoped>

</style>